import { useState, memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import smallYellowStarsIcon from 'images/icons/generativeAi/yellow_stars_16x16.gif';
import { TicketSummaryTooltip } from 'common/components/summaryPopup/Tooltip';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useLinkedSrLists, useSR } from 'remote-state/ticketServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { IdFieldWrapperStyled } from './styles';
import { setSelectRenderValue } from '../../../slice';

const IdFieldRender = memo(({ srId }) => {
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const [isIdhovered, setIsIdHovered] = useState(false);
  const isShowAiIcon = isIdhovered && isAiEnabled;
  const { sr: { data: srObject }, template } = useSR(srId);
  const dispatch = useDispatch();

  const fields = useMemo(
    () => {
      const headerFields = template?.data?.header || [];
      const sectionFields = template?.data?.sections?.flatMap((section) => section.sectionRows.flatMap((row) => row.fields)) || [];

      return [...headerFields, ...sectionFields];
    },
    [template?.data?.header, template?.data?.sections],
  );

  useLinkedSrLists({
    sr: srObject,
    fields,
    dependantFields: template?.data?.dependantFields,
    updateSrFunc: async (object) => {
      const fieldNameToRefresh = Object.keys(object || {})[0];
      dispatch(setSelectRenderValue({ srId, fieldName: fieldNameToRefresh, fieldValue: object?.[fieldNameToRefresh] }));
    }
  });

  return (
    <Box data-testid={`ticket-id-${srId}`}>
      <TicketSummaryTooltip>
        <IdFieldWrapperStyled onMouseEnter={() => setIsIdHovered(true)} onMouseLeave={() => setIsIdHovered(false)}>
          <div className="rowIDelem" sysaid-cell-id={srId}>
            {srId}
          </div>
          <img src={smallYellowStarsIcon} alt="" style={{ visibility: isShowAiIcon ? 'visible' : 'hidden' }} />
        </IdFieldWrapperStyled>
      </TicketSummaryTooltip>
    </Box>
  );
});

export default IdFieldRender;
