import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SwitchToggle } from '../../controls/Switch/SwitchToggle';
import { selectAuditLog } from '../../../../features/resolutionPanel/middlePanel/auditLog/store/slice';
import { useAuditLog } from '../../../../features/resolutionPanel/middlePanel/auditLog/hooks';
import usePendoTrackEvents from '../../../utils/hooks/usePendoTrackEvents';
import { APP_CONSTANTS, PENDO_TRACK_EVENTS } from '../../../../constants';

export const ExpandButton = () => {
    const ticketAuditLogs = useSelector(selectAuditLog);
    const { selectedFilterType, toggleAuditLogsProperty } = useAuditLog();
    const currentAuditLogs = ticketAuditLogs[selectedFilterType];

    let isAllMessagesExpanded = localStorage.getItem(APP_CONSTANTS.IS_ALL_MESSAGES_EXPANDED) === 'true';

    if (typeof isAllMessagesExpanded !== 'boolean') {
        isAllMessagesExpanded = false;
        localStorage.setItem(APP_CONSTANTS.IS_ALL_MESSAGES_EXPANDED, 'false');
    }

    const [isExpanded, setIsExpanded] = useState(isAllMessagesExpanded);
    const pendoTrackEvents = usePendoTrackEvents();

    const switchStatusHandler = ({ target: { checked } }) => {
        const auditLogs = currentAuditLogs?.list.filter((auditLog) => 'isRichTextTruncated' in auditLog);
        const truncatedLogsIds = auditLogs
            .filter(({ isRichTextTruncated }) => isRichTextTruncated === checked)
            .map(({ id }) => id);
        const allLogsIds = auditLogs.map(({ id }) => id);

        setIsExpanded(checked);
        localStorage.setItem(APP_CONSTANTS.IS_ALL_MESSAGES_EXPANDED, JSON.stringify(checked));

        const idsToToggle = truncatedLogsIds.length === auditLogs.length ? allLogsIds : truncatedLogsIds;
        pendoTrackEvents(checked ? PENDO_TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_ON : PENDO_TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_OFF);
        toggleAuditLogsProperty({ auditLogIds: idsToToggle, property: 'isRichTextTruncated' });
    };

    return (
        <SwitchToggle dataTestId="expand-switch" isChecked={isExpanded} handleSwitchChange={switchStatusHandler} />
    );
};
