import { styled } from '@mui/material/styles';
import { merge } from 'lodash-es';

export const StyledRichTextFieldWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isTemplatePage', 'isRichTextTruncated', 'isExpanded', 'isError', 'isSimpleContent'].includes(prop),
})(({ theme, isTemplatePage, isRichTextTruncated, isExpanded, isError, isSimpleContent }) => {
  let styles = {
    position: 'relative',

    '& .warning-icon-wrapper': {
      position: 'absolute',
      top: '4px',
      right: '10px',
    },

    '& .MuiGrid-root .MuiBox-root .richtext-wrap': {
      border: '1px solid transparent',
      padding: '0px 8px',
      '& .fr-wrapper': (!isRichTextTruncated || isSimpleContent) && {
        maxHeight: 'max-content !important',
      },
      '& .fr-wrapper .fr-element': {
        minHeight: 'auto',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        ...(isRichTextTruncated && { WebkitLineClamp: '7' }),
      },
      '& .fr-placeholder': {
        zIndex: '2',
      },
      '&:hover': !isExpanded && {
        cursor: 'pointer',
        backgroundColor: isError ? theme.palette.labels?.bt_red_mid : theme.palette.background.bg_hover_main,
        '& .fr-wrapper .fr-element': {
          backgroundColor: isError ? theme.palette.labels?.bt_red_mid : theme.palette.background.bg_hover_main,
        },
        '.fr-placeholder': isError && {
          color: theme.palette.labels?.bt_red_dark,
        },
        '& .warning-icon *': isError && {
          fill: theme.palette.labels?.bt_red_dark,
        },
      },
    },
    '& .rich-text-editor-wrapper .richtext-wrap': isError &&
      !isExpanded && {
        background: theme.palette.bt_red_light?.main,
        '& .fr-wrapper .fr-element': {
          background: theme?.palette?.bt_red_light?.main,
        },
        '.fr-placeholder': {
          color: theme.palette?.labels?.bt_red_reg,
        },
      },
  };

  if (isTemplatePage || isExpanded) {
    styles = {
      '& .MuiGrid-root .MuiBox-root .richtext-wrap': {
        border: theme.shape.border.border,
        '& .fr-wrapper .fr-element': {
          minHeight: '60px',
        },
        '& .fr-element.fr-view': {
          paddingRight: '44px',
        },
      },
      '& span[data-error-tooltip=true], .warning-icon': {
        display: 'none',
      },
    };
  }

  const defaultStyles = {
    marginTop: !isTemplatePage && '6px',
    marginLeft: '-8px',
    '& .truncate-text-button': {
      padding: '0 8px',
    },
  };

  return merge(defaultStyles, styles);
});
