import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledConvertTicket } from './style';
import useTexts from './useTexts';
import { CONSTANTS } from '../../constants';
import ConversionPrompt from './conversionPrompt';
import usePendoTrackEvents from '../../../../common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from '../../../../constants';
import { useHandleTicketLock, useTicketLockStatus } from '../../../../remote-state/ticketServiceHooks';
import { selectActiveUser } from '../../../../store/userSlice';

function ConvertTicket(props) {
    const { srId, srType, isFromQueue } = props;
    const {
        convertToRequestText,
        convertToIncidentText,
    } = useTexts(srId);

    const [openPrompt, setOpenPrompt] = useState(false);
    const pendoTrackEvents = usePendoTrackEvents();

    const isIncident = srType === CONSTANTS.INCIDENT_TYPE;

    function getQueueConversionEvent() {
        return srType === CONSTANTS.INCIDENT_TYPE ? PENDO_TRACK_EVENTS.convert_to_request_queue : PENDO_TRACK_EVENTS.convert_to_incident_queue;
    }

    function getTicketPageConversionEvent() {
        return srType === CONSTANTS.INCIDENT_TYPE ? PENDO_TRACK_EVENTS.convert_to_request_ticket_page : PENDO_TRACK_EVENTS.convert_to_incident_ticket_page;
    }
    const userAccount = useSelector(selectActiveUser);

    const convertionPendoEvent = isFromQueue ? getQueueConversionEvent() : getTicketPageConversionEvent();
    const { mutate: lockSR } = useHandleTicketLock(srId);
    const { data: lockingDetails } = useTicketLockStatus(srId);

    const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
    const handleClickOpen = () => {
        pendoTrackEvents(convertionPendoEvent, { srId });
        setOpenPrompt(true);
        if(!isTicketLocked && !isFromQueue) {
            lockSR({ username: userAccount?.username, srId, queueLocking: false });
        }
    };

    return (
      <StyledConvertTicket onClick={handleClickOpen}>
        <button className="convert-ticket" data-testid="convert-ticket-button">
          <span className="convert-ticket-span" data-testid="convert-ticket-span">{ isIncident ? convertToRequestText : convertToIncidentText || 'Convert Ticket'}</span>
        </button>
        { openPrompt &&
          <div data-testid="conversion-prompt">
            <ConversionPrompt
              open={openPrompt}
              setPromptOpen={setOpenPrompt}
              {...props}
            />
          </div>
            }
      </StyledConvertTicket>
    );
}

export default ConvertTicket;
