import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const AssigneeMenuWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['isQueueTableFilter', 'isWideDropdown'].includes(prop),
})(({ theme, isQueueTableFilter, isWideDropdown }) => ({
  '& .options_wrapper': {
    paddingRight: isQueueTableFilter ? '0' : theme.spacing(1.5),
    '& .assignee-item-wrapper': {
      width: 'auto',
      '& .text': {
        maxWidth: isWideDropdown && '206px',
      },
    },
  },
}));

export const TeamsText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  flexGrow: 0,
  fontSize: '0.75rem',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '20px',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: theme.palette.text.secondary_txt,
}));
