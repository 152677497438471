import { memo, useCallback, useContext, useMemo, useState, createContext, useEffect } from 'react';
import { useSelectControl } from './useSelectControl';

export const DropdownSelectContext = createContext({});

export const DropdownSelectProvider = memo((props) => {
  const {
    children,
    initialOptions,
    handleChange,
    isMultiple,
    selection,
    keyString,
    isCategory,
    hasHierarchy,
    isGroupedHierarchy,
    valueClassesNames,
  } = props;

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    setExpanded(isGroupedHierarchy && valueClassesNames ? Object.keys(valueClassesNames) : []);
  }, [isGroupedHierarchy, valueClassesNames]);

  const selectControl = useSelectControl(
    initialOptions,
    isMultiple,
    handleChange,
    selection,
    keyString,
    isCategory,
    hasHierarchy,
    isGroupedHierarchy,
  );

  const toggleExpansion = useCallback((nodeId) => {
    setExpanded((prev) => (prev.includes(nodeId) ? prev.filter((current) => current !== nodeId) : [...prev, nodeId]));
  }, []);

  const expandAll = useCallback(() => {
    setExpanded(Object.keys(initialOptions));
  }, [initialOptions]);

  const value = useMemo(
    () => ({
      ...props,
      ...selectControl,
      toggleExpansion,
      expandAll,
      expanded,
    }),
    [props, selectControl, toggleExpansion, expandAll, expanded],
  );

  return <DropdownSelectContext.Provider value={value}>{children}</DropdownSelectContext.Provider>;
});

export const useSelectContext = () => useContext(DropdownSelectContext);

export const useOptionById = (id) => useContext(DropdownSelectContext).optionsMap[id];
