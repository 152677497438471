import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import { setToasterMessage } from 'store/globalSlice';
import { useDeleteAttachmentMutation } from 'remote-state/attachments';

import useTexts from '../useTexts';
import { useDeleteScreenCapture } from '../../../remote-state/useScreenCapturesHooks';

const DeleteAttachment = ({
  fileId,
  attachmentId,
  fileName,
  children,
  onAttachmentDeleted,
  shouldTriggerDeleteApi,
  attachmentUsageCount,
  isScreenCapture,
  screenCaptureType,
  ...rest
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { mutateAsync: deleteAttachmentApi } = useDeleteAttachmentMutation();
  const { mutateAsync: deleteScreenCapture } = useDeleteScreenCapture();

  const {
    delete: { title, successMessage, failMessage },
    multipleDelete: { title: multipleDeleteTitle, description: multipleDeleteDescription },
  } = useTexts();

  const promptTitle = attachmentUsageCount > 1 ? multipleDeleteTitle : `${title} ${fileName}?`;
  const promptDescription = attachmentUsageCount > 1 ? multipleDeleteDescription : '';
  const hasDuplicates = attachmentUsageCount > 1;

  const dispatch = useDispatch();

  const handleDelete = (event) => {
    event.stopPropagation();
    setShowPrompt(true);
  };

  const handleShowToaster = (message, type = 'success') => {
    dispatch(setToasterMessage({ message, type }));
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleOkClick = async () => {
    handleClosePrompt();

    if (isScreenCapture) {
      try {
        await deleteScreenCapture(screenCaptureType);
        handleShowToaster(successMessage);
      } catch {
        handleShowToaster(failMessage, 'error');
      }

      return;
    }

    try {
      if (shouldTriggerDeleteApi) {
        await deleteAttachmentApi({ attachmentId: fileId || attachmentId, isDeleteDuplicate: hasDuplicates });
      }
      onAttachmentDeleted(attachmentId);
      handleShowToaster(successMessage);
    } catch (error) {
      handleShowToaster(failMessage, 'error');
    }
  };

  return (
    <>
      <IconButton data-testid="delete-btn" {...rest} onClick={handleDelete}>
        {children}
      </IconButton>
      {showPrompt && (
        <MessagePrompt
          open
          title={promptTitle}
          children={promptDescription}
          Icon={WarningIcon}
          onOkClick={handleOkClick}
          showCancelBtn
          onClose={handleClosePrompt}
        />
      )}
    </>
  );
};

DeleteAttachment.propTypes = {
  attachmentId: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  onAttachmentDeleted: PropTypes.func,
  children: PropTypes.element.isRequired,
  shouldTriggerDeleteApi: PropTypes.bool,
};

DeleteAttachment.defaultProps = {
  fileName: '',
  onAttachmentDeleted: () => {},
  shouldTriggerDeleteApi: true,
};
export default DeleteAttachment;
