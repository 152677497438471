import { useMutation, useQuery } from '@tanstack/react-query';
import { getOpenaiSummarizeTicket, postFeedbackOnSummary } from 'services/sysaiService';

export const useTicketSummaryByIdQuery = (srId) =>
  useQuery({
    queryKey: ['ticketSummary', srId],
    queryFn: () => getOpenaiSummarizeTicket(srId),
    cacheTime: 0,
    staleTime: 0,
  });

export const usePostTicketSummaryFeedback = () =>
  useMutation({
    mutationKey: ['ticketSummaryFeedback'],
    mutationFn: postFeedbackOnSummary,
  });
