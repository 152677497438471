import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { getTicketLockStatus } from 'services/ticketService';
import useOutsideRowsClick from 'common/utils/hooks/useOutsideRowsClick';
import { selectOnPopupAdvanceMessage } from 'store/globalSlice';
import { selectActiveUser } from 'store/userSlice';
import { removePrimaryCategory } from 'store/categorySlice';
import { selectUpdatingSRs } from 'store/srSlice';
import {
  resetRequiredFieldsList,
  resetSrClearRequiredField,
  resetSrSaved,
  resetSrSaveFieldsPermissionError,
  resetSrSaveStatusError,
  resetSrSaveUnresolvedTicketError,
  selectSrIsSaved,
  selectSrIsSaveFieldsPermissionError,
  selectSrIsSaveStatusError,
  selectSrIsSaveUnresolvedTicketError,
} from 'store/saveSrValidationSlice';
import { resetSrDetailsForSummary, selectIsSummaryTooltipOpen } from 'store/ticketSummarySlice';
import { QUERIES_KEYS } from 'constant';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import FieldsNotSavedPrompt from 'common/components/fieldsNotSavedPrompt';
import {
  useHandleTicketLock,
  useHandleTicketUnlock,
  useIsLastRowWithData,
  useQueueCountTotal,
  useTicketLockStatus,
  useTicketsLockMap,
} from 'remote-state/ticketServiceHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { arrayMove } from '@dnd-kit/sortable';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import {
  removeSelectedTicket,
  selectActiveView,
  selectColumnsOrder,
  selectedQueueTicket,
  selecteSrIdToRefreshQueue,
  selectFilterModel,
  selectFullSortModel,
  selectGridMode,
  selectRedirectURL,
  selectSearchText,
  setActiveSr,
  setColumnsOrder,
  setGridMode,
  setHoveredSR,
  setRedirectURL,
  setSelectedTickets,
  setSrIdToRefreshQueue,
  updateSelectedTicket,
} from '../slice';
import ActionBar from './actionBar';
import { COLUMN_MENU, GRID, GRID_MODELS, SAVE_ERRORS } from './constants';
import { StyledGrid, StyledGridContainer, StyledGridStyle } from './style';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { useGridDataSource } from './hooks';
import GridComponent from './GridComponent';
import { convertFiltersToGridFilterModel } from './helpers';
import { TICKETS_OPEN_URL } from '../../../constants';

const isDocumentVisible = () => document.visibilityState === 'visible';

function Grid(props) {
  const { className, scrollToIndex, scrollToId, showFilters, columnDefs } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const queryClient = useQueryClient();

  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [columnHeaderClass, setColumnHeaderClass] = useState('');
  const [isClickOutsidePopup, setIsClickOutsidePopup] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [searchIntervalTime, setSearchIntervalTime] = useState(GRID.REFETCH_INTERVAL_MILLIS);

  const gridRef = useRef();
  const firstMountRef = useRef(null);
  const gridRefHolder = useRef();
  const waitForLock = useRef();
  const waitForUnlock = useRef();
  const waitForUpdate = useRef();
  const isGridReady = useRef(false);
  const rowIndex = useRef(false);
  const refetchTimeout = useRef();
  const handleOkClickRedrawRowsTimeout = useRef();
  const handleOkClickSetSelectedTimeout = useRef();
  const handleCancelClickHideModalTimeout = useRef();
  const handleCancelClickSetSelectedTimeout = useRef();

  const redirectURL = useSelector(selectRedirectURL);
  const onPopupAdvanceMessage = useSelector(selectOnPopupAdvanceMessage);
  const tableBody = document?.getElementsByClassName('ag-body-viewport')?.[0];
  const selectedTicket = useSelector(selectedQueueTicket);
  const userAccount = useSelector(selectActiveUser);
  const username = userAccount?.username;
  const { data: isLastRowWithData } = useIsLastRowWithData();
  const columnsOrder = useSelector(selectColumnsOrder);
  const isSrSaved = useSelector(selectSrIsSaved);
  const isSaveStatusError = useSelector(selectSrIsSaveStatusError);
  const isSaveFieldsPermissionError = useSelector(selectSrIsSaveFieldsPermissionError);
  const isSaveUnresolvedTicketError = useSelector(selectSrIsSaveUnresolvedTicketError);
  const srIdToRefreshQueue = useSelector(selecteSrIdToRefreshQueue);
  const isSummaryTooltipOpen = useSelector(selectIsSummaryTooltipOpen);
  const updatingSrs = useSelector(selectUpdatingSRs);
  const mode = useSelector(selectGridMode);
  const activeView = useSelector(selectActiveView);
  const sortModel = useSelector(selectFullSortModel);
  const filterModel = useSelector(selectFilterModel);
  const queueSearchText = useSelector(selectSearchText);
  global.queueAbortController = new AbortController();

  const { data: currentUserPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const isBulkActionsEnabled = currentUserPermissions?.userPermissionEnableBulkActionsFromList;

  const prevNodeId = usePreviousValue(currentNodeId);

  const { data: countTotal } = useQueueCountTotal();
  const { data: lockedRows } = useTicketsLockMap();

  const { data: currentLockingDetails } = useTicketLockStatus(currentNodeId);
  const { data: prevLockingDetails } = useTicketLockStatus(prevNodeId);

  const { mutate: unlockPrevNodeId } = useHandleTicketUnlock(prevNodeId);
  const { mutate: lockCurrentNodeId } = useHandleTicketLock(currentNodeId);

  const { data: waitForUnlockLockingDetails } = useTicketLockStatus(waitForUnlock.current);
  const { mutate: lockWaitForUnlock } = useHandleTicketLock(waitForUnlock.current);

  const { data: waitForLockLockingDetails } = useTicketLockStatus(waitForLock.current);
  const { mutate: unlockWaitForLock } = useHandleTicketUnlock(waitForLock.current);

  const { mutate: unlockWaitForUpdate } = useHandleTicketUnlock(waitForUpdate.current);

  const ticketsPerPage = gridRef?.current?.api?.paginationGetPageSize();

  const cleanTimersAndRequests = useCallback(() => {
    clearTimeout(refetchTimeout.current);
    global.queueAbortController?.abort(); // cancel on going search requests
  }, []);

  const refreshQueue = useCallback(() => {
    cleanTimersAndRequests();
    queryClient.invalidateQueries({ queryKey: ['queueData'] });
  }, [cleanTimersAndRequests, queryClient]);

  const refreshQueueWithDelay = useCallback(() => {
    refetchTimeout.current = setTimeout(() => {
      refreshQueue();
    }, searchIntervalTime);
  }, [refreshQueue, searchIntervalTime]);

  const setSortModel = useCallback(
    (sortModel) => {
      if (isGridReady.current) {
        gridRef?.current?.columnApi?.applyColumnState({
          state: sortModel,
          defaultState: { sort: null },
        });
      }
    },
    [gridRef],
  );

  const setFilterModel = useCallback(
    (filterModel) => {
      if (isGridReady.current) {
        gridRef?.current?.api?.setFilterModel(convertFiltersToGridFilterModel(filterModel));
      }
    },
    [gridRef],
  );

  const scrollToRowIndex = useCallback(
    (rowIndex) => {
      if (!gridRef?.current.api || typeof rowIndex === 'undefined') return;

      if (gridRef.current.api.getInfiniteRowCount() < rowIndex + 1) {
        gridRef.current.api.setRowCount(rowIndex + GRID.ROWS_PER_SCREEN, false);
      }
      gridRef.current.api.ensureIndexVisible(rowIndex, 'top');
    },
    [gridRef],
  );

  const { gridDataSource, isGridEmptyState, refetchIntervalMillis } = useGridDataSource({
    gridRef,
    gridMode: mode,
    searchAfterFetchCallback: refreshQueueWithDelay,
    searchBeforeFetchCallback: cleanTimersAndRequests,
  });

  const onGridReady = useCallback(
    async (params) => {
      isGridReady.current = true;
      gridRef.current.additional = {};

      params.api.setDatasource(gridDataSource);

      if (filterModel?.length > 0) {
        setFilterModel(filterModel);
      }

      if (sortModel?.length > 0) {
        gridRef.current.additional.sortFromApi = true;
        setSortModel(sortModel);
      }

      scrollToRowIndex(scrollToIndex);
    },
    [gridDataSource, gridRef, sortModel, filterModel, setSortModel, setFilterModel, scrollToIndex, scrollToRowIndex],
  );

  const prevActiveView = usePreviousValue(activeView);
  const prevSortModel = usePreviousValue(sortModel);
  const prevFilterModel = usePreviousValue(filterModel);
  const prevColumnsOrder = usePreviousValue(columnsOrder);
  const prevQueueSearchText = usePreviousValue(queueSearchText);

  if (activeView && prevActiveView && prevActiveView.name !== activeView.name) {
    // handle view change - fetch new data only after all models are updated in the search params
    setFilterModel(filterModel); // our column definition is not configured to filter changes so setFilterModel needs to be before setSortModel in order for the getRows to be called in the right time - don't change order
    gridRef.current.additional.sortFromApi = true;
    setSortModel(sortModel);
  } else if (prevActiveView?.name === activeView?.name && isGridReady.current) {
    if (!isEqual(prevColumnsOrder, columnsOrder) || !isEqual(prevQueueSearchText, queueSearchText)) {
      gridRef.current.api.refreshInfiniteCache(); // refreshInfiniteCache to invoke getRows on columnsOrder change (we keep columnsOrder in context object)
    } else if (!isEqual(prevFilterModel, filterModel)) {
      setFilterModel(filterModel);
      gridRef.current.api.refreshInfiniteCache(); // our column definition is not configured to filter changes - need to refreshInfiniteCache to invoke getRows
    } else if (!isEqual(prevSortModel, sortModel)) {
      setSortModel(sortModel);
      gridRef.current.api.refreshInfiniteCache();
    }
  }

  const prevLockedRows = usePreviousValue(lockedRows);

  useEffect(() => {
    performance.mark(PERFORMANCE_MEASURE.QUEUE.MOUNT);
  }, []);

  useEffect(() => {
    setSearchIntervalTime(refetchIntervalMillis);
  }, [refetchIntervalMillis]);

  useEffect(() => {
    if (prevLockedRows !== lockedRows && lockedRows) {
      Object.keys(lockedRows).forEach((item) => {
        const lockedRow = lockedRows[item];
        if (lockedRow) {
          const { locked, name } = lockedRow;
          queryClient.setQueryData(['ticketLockStatus', item], () => ({
            isLocked: locked,
            lockingUser: locked ? name : username,
          }));
        }
      });
    }
  }, [prevLockedRows, lockedRows, queryClient, username]);

  if (waitForUnlock.current && !waitForUnlockLockingDetails.isLocked) {
    // attempt to lock after succeful unlock (for cases that first attempt failed because a previous unlock request haven't finised)
    lockWaitForUnlock({ srId: waitForUnlock.current, username, queueLocking: true });
    waitForUnlock.current = false;
  }

  if (
    waitForLock.current &&
    waitForLockLockingDetails &&
    waitForLockLockingDetails.isLocked &&
    waitForLockLockingDetails.lockingUser === username
  ) {
    // attempt to unlock after succeful lock (for cases that first attempt failed because a previous lock request haven't finised)
    unlockWaitForLock({ srId: waitForLock.current, username, queueLocking: true });
    waitForLock.current = false;
  }

  if (waitForUpdate.current && !updatingSrs.includes(waitForUpdate.current)) {
    // unlock after finish updating
    unlockWaitForUpdate({ srId: waitForUpdate.current, username });
    waitForUpdate.current = false;
  }

  useEffect(() => {
    if (prevNodeId !== currentNodeId) {
      if (prevNodeId) {
        if (
          prevLockingDetails.isLocked &&
          prevLockingDetails.lockingUser === username &&
          !updatingSrs.includes(prevNodeId)
        ) {
          unlockPrevNodeId({ srId: prevNodeId, username });
        } else if (!updatingSrs.includes(prevNodeId)) {
          waitForLock.current = prevNodeId;
        } else {
          waitForUpdate.current = prevNodeId;
        }
      }
      if (currentNodeId) {
        if (!currentLockingDetails.isLocked) {
          lockCurrentNodeId({ srId: currentNodeId, username, queueLocking: true }); // attempt to unlock
        } else {
          waitForUnlock.current = currentNodeId;
        }
      }
    }
  }, [
    prevNodeId,
    currentNodeId,
    currentLockingDetails.isLocked,
    lockCurrentNodeId,
    prevLockingDetails,
    unlockPrevNodeId,
    updatingSrs,
    username,
  ]);

  const updateGridMode = useCallback(() => {
    if (isDocumentVisible()) {
      if ((currentNodeId && !isGridEmptyState) || onPopupAdvanceMessage || isSummaryTooltipOpen) {
        dispatch(setGridMode(GRID_MODELS.Zen));
      } else {
        dispatch(setGridMode(GRID_MODELS.Realtime));
      }
    } else {
      dispatch(setGridMode(GRID_MODELS.UnvisibleTab));
    }
  }, [isGridEmptyState, currentNodeId, dispatch, onPopupAdvanceMessage, isSummaryTooltipOpen]);

  useEffect(() => {
    updateGridMode();
  }, [updateGridMode]);

  useEffect(() => {
    document.addEventListener('visibilitychange', updateGridMode);
    return () => {
      document.removeEventListener('visibilitychange', updateGridMode);
    };
  }, [updateGridMode]);

  const updateGridRefetchPolicy = useCallback(() => {
    switch (mode) {
      case GRID_MODELS.Realtime:
        //If callback chain with onGridReady is stopped by switching mode then we should initiate new process to restart update data cycling
        refreshQueue();
        break;
      case GRID_MODELS.Zen:
      case GRID_MODELS.UnvisibleTab:
        cleanTimersAndRequests();
        break;
      default:
        throw new Error(`Grid Mode ${mode} not supported`);
    }
  }, [mode, cleanTimersAndRequests, refreshQueue]);

  const useOutsideRowsClickCallback = useCallback(() => {
    if (currentNodeId) {
      setRowData(null);
      setCurrentNodeId(null);
      setActiveRowIndex(null);
      dispatch(setActiveSr(null));
      gridRef?.current?.api?.clearFocusedCell();
    } else {
      gridRef?.current?.api?.clearFocusedCell();
    }
  }, [currentNodeId, dispatch]);

  const useOutsideRowsClickIgnoreSelectors = useMemo(() => ['.MuiPaper-elevation'], []);

  useOutsideRowsClick(useOutsideRowsClickCallback, useOutsideRowsClickIgnoreSelectors);

  const onRowClicked = useCallback(
    async (e) => {
      const clickedNodeId = Number(e.node.id);
      const clickedRowIndex = e.rowIndex;
      const readOnlyFields = e?.data?.filter((field) => field.readOnly);
      const isReadOnly = readOnlyFields?.some((readOnlyField) => readOnlyField.field === selectedCell);
      const isActiveRowIndexChanged = activeRowIndex !== clickedRowIndex;
      const isInputElementClicked = !e?.event?.target?.outerHTML.toString().includes('ag-cell');

      let isActiveSrLocked = false;
      if (currentNodeId) {
        isActiveSrLocked = currentLockingDetails.isLocked;
      }

      if (
        isActiveRowIndexChanged &&
        ((isInputElementClicked && !isReadOnly && !isActiveSrLocked) || isActiveSrLocked) &&
        activeRowIndex !== clickedRowIndex
      ) {
        rowIndex.current = clickedRowIndex;
        setActiveRowIndex(clickedRowIndex);
        setRowData(e?.data);
        setCurrentNodeId(clickedNodeId);
        dispatch(setActiveSr(e.node.data));
      }
    },
    [activeRowIndex, currentLockingDetails.isLocked, currentNodeId, dispatch, selectedCell],
  );

  const onCellClicked = useCallback((e) => {
    setSelectedCell(e?.colDef?.field);
    const isCustomFields = ['requestUser', 'srType', 'urgency'].includes(e.colDef.field);

    if (isCustomFields) {
      setIsModal(true);
    }
  }, []);

  const onFilterOpened = useCallback((e) => {
    if (e.source === COLUMN_MENU) {
      setColumnHeaderClass(e.column.colDef.headerClass);
    }
  }, []);

  const handleColumnMoved = useCallback(
    (event) => {
      const movedNodeDisplayName = event?.column?.userProvidedColDef?.headerName;
      let columns = gridRef.current.api.getColumnDefs();
      columns = columns.filter((c) => c.colId !== 'openticket');
      columns?.splice(0, 2);
      const movedColumn = columns.find((col) => col?.headerName === movedNodeDisplayName);
      let newIndex = columns.indexOf(movedColumn);
      const oldIndex = columnsOrder.findIndex(({ id }) => id === movedColumn?.fieldId);

      if (newIndex === 0) {
        newIndex = 1;
      }

      const newOrder = arrayMove(columnsOrder, oldIndex, newIndex);
      // set the columns in the active view
      if (oldIndex !== newIndex) {
        dispatch(setColumnsOrder(newOrder));
      }
    },
    [dispatch, columnsOrder],
  );

  const tabToNextCell = useCallback((params) => {
    setIsModal(false);
    const nextCellPosition = params.nextCellPosition;
    const currentRow = gridRef?.current?.api?.getDisplayedRowAtIndex(nextCellPosition?.rowIndex);
    setCurrentNodeId(currentRow.id);

    return {
      rowIndex: nextCellPosition?.rowIndex,
      column: nextCellPosition?.column,
    };
  }, []);

  const handleOkClick = useCallback(async () => {
    //We will had to wrap redrawing function calls into setTimeout either way it would throw an error with the
    // error message like this "AG Grid: cannot get grid to draw rows when it is in the middle of drawing rows...  To overcome this, put the API call into a timeout, e.g. instead of api.redrawRows()..."
    //There are enormous amount of reports in the github repo related this issue but AG-grid doesnt gave us other soulution rather than wrapping row and queue updates into timers. In these way we schedule updates in V8 callstack and in this way updates(in our case its updates on realtime and this point) wouldnt have collision in ag grid.
    handleOkClickRedrawRowsTimeout.current = setTimeout(() => {
      if (!redirectURL) {
        const currentRowNode = gridRef?.current?.api?.getRowNode(currentNodeId);
        const prevRowNode = gridRef?.current?.api?.getRowNode(prevNodeId);
        if (currentRowNode && prevRowNode) {
          gridRef?.current?.api?.redrawRows({
            rowNodes: [currentRowNode, prevRowNode],
          });
        }
      }
    });
    dispatch(removePrimaryCategory({ id: currentNodeId, value: null }));
    if (!isSrSaved) {
      dispatch(resetSrSaved());
      if (isSaveFieldsPermissionError) {
        dispatch(resetSrSaveFieldsPermissionError());
      }
      const isStatusUpdateError = isSaveStatusError || isSaveUnresolvedTicketError;
      if (isStatusUpdateError) {
        if (isSaveStatusError) {
          dispatch(resetSrSaveStatusError());
          dispatch(resetRequiredFieldsList());
          dispatch(resetSrClearRequiredField());
        }
        dispatch(resetSrSaveUnresolvedTicketError());
        const srId = rowData.find((cellData) => cellData.field === 'id').value;
        router.navigate({ to: TICKETS_OPEN_URL.SPACES(srId) });
      }
    } else {
      setCurrentNodeId(null);
      if (redirectURL) {
        const redirect = redirectURL;
        dispatch(setRedirectURL(null));
        router.navigate({ to: redirect });
      }
    }
    setActiveRowIndex(null);
    gridRef?.current?.api?.clearFocusedCell();

    handleOkClickSetSelectedTimeout.current = setTimeout(() => {
      const currentRow = gridRef?.current?.api?.getDisplayedRowAtIndex(rowIndex.current);
      if (currentRow?.setSelected) {
        currentRow?.setSelected(false);
      }
    }, 0);
  }, [
    router,
    rowData,
    dispatch,
    isSrSaved,
    prevNodeId,
    redirectURL,
    currentNodeId,
    isSaveStatusError,
    isSaveUnresolvedTicketError,
    isSaveFieldsPermissionError,
  ]);

  const handleRowSelected = useCallback(
    ({ data, node }) => {
      const selectedTicketCount = selectedTicket.length;
      data.forEach(async ({ field, value }) => {
        if (field === 'id') {
          let lockingDetails = {};
          try {
            lockingDetails = await queryClient.fetchQuery({
              queryKey: ['ticketLockStatus', value],
              queryFn: () => getTicketLockStatus({ username, srId: value }),
              select: (data) => ({
                isLocked: data?.isLocked || data?.ticketLock, // ticketLock in case of first fetch from server, isLocked in case of cache
                lockingUser: data?.lockingUser || data?.lockUsername,
              }),
            });
          } catch (error) {
            // @TODO handle case of error
            console.log(error);
          }
          const isLocked = lockingDetails.isLocked;
          const lockingUser = lockingDetails.lockingUser;
          const isTicketLockedByAnotherUser = isLocked && lockingUser !== username;

          if (node.selected) {
            if (!isTicketLockedByAnotherUser && selectedTicketCount < ticketsPerPage) {
              dispatch(updateSelectedTicket({ id: value, rowIndex: node.rowIndex }));
            } else {
              node.setSelected(false);
            }
          } else {
            dispatch(removeSelectedTicket(value));
          }
        }
      });
    },
    [ticketsPerPage, dispatch, queryClient, selectedTicket?.length, username],
  );

  const handleUncheckRows = useCallback(
    (uncheckRows = null) => {
      if (uncheckRows?.length) {
        uncheckRows.forEach(({ id, rowIndex }) => {
          const currentRow = gridRef?.current?.api?.getDisplayedRowAtIndex(rowIndex);
          currentRow.setSelected(false);
          dispatch(removeSelectedTicket(id));
        });
      } else {
        gridRef?.current?.api?.deselectAll();
        dispatch(removeSelectedTicket());
      }
    },
    [dispatch],
  );

  const handleCancelClick = useCallback(() => {
    if (!isSrSaved) {
      dispatch(resetSrSaved());
      if (isSaveStatusError) {
        dispatch(resetSrSaveStatusError());
        dispatch(resetSrClearRequiredField());
      }
      if (isSaveUnresolvedTicketError) {
        dispatch(resetSrSaveUnresolvedTicketError());
      }
    }

    if (redirectURL) {
      dispatch(setRedirectURL(null));
    }

    handleCancelClickHideModalTimeout.current = setTimeout(() => {
      const modal = document.getElementsByClassName('MuiPaper-root');
      if (modal && modal?.[1]) {
        modal[0].style.display = 'none';
      }
    }, 1000);
    gridRef?.current?.api?.clearFocusedCell();

    handleCancelClickSetSelectedTimeout.current = setTimeout(() => {
      const currentRow = gridRef?.current?.api?.getDisplayedRowAtIndex(rowIndex.current);
      if (currentRow?.setSelected) {
        currentRow?.setSelected(false);
      }
    }, 0);
  }, [dispatch, isSrSaved, redirectURL, isSaveStatusError, isSaveUnresolvedTicketError]);

  useEffect(() => {
    firstMountRef.current = true;
    return () => {
      cleanTimersAndRequests();
      clearTimeout(handleOkClickRedrawRowsTimeout.current);
      clearTimeout(handleOkClickSetSelectedTimeout.current);
      clearTimeout(handleCancelClickHideModalTimeout.current);
      clearTimeout(handleCancelClickSetSelectedTimeout.current);
      dispatch(setSelectedTickets([]));
      delete global.queueAbortController;
    };
  }, [dispatch, cleanTimersAndRequests]);

  useEffect(() => {
    if (srIdToRefreshQueue) {
      const currentRowNode = gridRef?.current?.api?.getRowNode(srIdToRefreshQueue);
      if (currentRowNode) {
        gridRef?.current?.api?.refreshCells({
          rowNodes: [gridRef?.current?.api.getRowNode(srIdToRefreshQueue.toString())],
        });
        dispatch(setSrIdToRefreshQueue(null));
      }
    }
  }, [srIdToRefreshQueue, dispatch]);

  const prevMode = usePreviousValue(mode);

  useEffect(() => {
    if (prevMode && mode && prevMode !== mode) {
      updateGridRefetchPolicy();
    }
  }, [prevMode, mode, updateGridRefetchPolicy]);

  useEffect(() => {
    if (isClickOutsidePopup) {
      setColumnHeaderClass('');
      setIsClickOutsidePopup(false);
    }
  }, [isClickOutsidePopup]);

  useEffect(() => {
    const listener = () => {
      dispatch(resetSrDetailsForSummary());
      dispatch(setHoveredSR(-1));
    };
    if (tableBody) tableBody?.addEventListener('mouseleave', listener);
    return () => {
      tableBody?.removeEventListener('mouseleave', listener, true);
    };
  }, [tableBody, dispatch]);

  const isShowMessagePrompt = useMemo(() => {
    if (!isSrSaved) {
      let saveError = SAVE_ERRORS.notSavedError;
      if (isSaveStatusError || isSaveUnresolvedTicketError) {
        saveError = SAVE_ERRORS.isSaveStatusError;
      }
      if (isSaveFieldsPermissionError) {
        saveError = SAVE_ERRORS.isSaveFieldsPermissionError;
      }
      return <FieldsNotSavedPrompt saveError={saveError} onOkClick={handleOkClick} onCancelClick={handleCancelClick} />;
    }
  }, [
    handleCancelClick,
    handleOkClick,
    isSaveFieldsPermissionError,
    isSaveStatusError,
    isSaveUnresolvedTicketError,
    isSrSaved,
  ]);

  const handleClosePopup = useCallback(() => {
    setIsClickOutsidePopup(true);
  }, []);

  const context = useMemo(
    () => ({ countTotal, columnsOrder, queueSearchText }),
    [countTotal, columnsOrder, queueSearchText],
  );

  return (
    <>
      {isShowMessagePrompt}
      <StyledGrid
        isLastRowWithData={isLastRowWithData}
        showFilters={showFilters}
        columnHeaderClass={columnHeaderClass}
        className={className}
        ref={gridRefHolder}
        isModal={isModal}
      >
        <StyledGridContainer>
          <StyledGridStyle className="ag-theme-alpine" data-testid="queue-grid" data-ready={isGridReady.current}>
            <GridComponent
              scrollToId={scrollToId}
              gridRef={gridRef}
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              handleClosePopup={handleClosePopup}
              onRowSelected={handleRowSelected}
              context={context}
              onCellClicked={onCellClicked}
              onRowClicked={onRowClicked}
              // onBodyScroll={onBodyScroll}
              handleColumnMoved={handleColumnMoved}
              onFilterOpened={onFilterOpened}
              tabToNextCell={tabToNextCell}
              navigateToNextCell={tabToNextCell}
            />
          </StyledGridStyle>
        </StyledGridContainer>
      </StyledGrid>
      {isBulkActionsEnabled && (
        <ActionBar
          selectedRows={selectedTicket}
          onSetIsCacheRefreshNeeded={refreshQueue}
          onUncheckRows={handleUncheckRows}
          ticketsPerPage={ticketsPerPage}
        />
      )}
    </>
  );
}
export default memo(Grid);
