import { ListItemText, MenuItem, styled } from '@mui/material';
import { useStyleHelpers } from '../hooks';

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) =>
    prop !== 'isMultiple' &&
    prop !== 'stylingVariant' &&
    prop !== 'isWideDropdown' &&
    prop !== 'displayHomeIcon' &&
    prop !== 'isInFocus' &&
    prop !== 'isExpandButton',
})(({ theme, checked, disabled, stylingVariant, isMultiple, isWideDropdown, displayHomeIcon, isInFocus, isExpandButton }) => {
  const { selectItemFontStyles } = useStyleHelpers({ stylingVariant, theme });
  let itemMaxWidth = '158px';
  const fontFamily = displayHomeIcon ? 'Roboto-Bold' : selectItemFontStyles().fontFamily;

  if (isWideDropdown) {
    itemMaxWidth = '266px';
  } else if (isMultiple) {
    itemMaxWidth = '130px';
  }

  return {
    display: 'flex',
    gap: isExpandButton ? 0 : theme.spacing(1),
    marginTop: isExpandButton ? '12px' : 0,
    padding: theme.spacing(0.5, 1),
    height: theme.spacing(5.25),
    width: '100%!important',
    borderRadius: '6px',
    backgroundColor: (checked && !disabled) || isInFocus ? theme.palette.background?.selected : 'transparent',
    border: isInFocus ? `1px dashed ${theme.palette.background?.blue_hover}` : 'transparent',
    ...(isExpandButton
      ? {
          '&::before': {
            content: '""',
            height: '1px',
            width: 'calc(100% - 24px)',
            backgroundColor: theme.palette.grey?.divider_grey_v1,
            borderRadius: '1px',
            position: 'absolute',
            top: '-12px',
          },
        }
      : {}),
    '&:hover': {
      backgroundColor:
        checked && !disabled
          ? `${theme.palette.background?.pages_background} !important`
          : `${theme.palette.background?.selected} !important`,
    },
    '& .MuiTypography-root': {
      fontSize: selectItemFontStyles().fontSize,
      color: displayHomeIcon ? theme.palette.text.txt_default : 'inherit',
      maxWidth: isExpandButton ? '151px' : itemMaxWidth,
      letterSpacing: '0.035em',
      fontFamily,
      overflow: isExpandButton ? 'auto' : 'hidden',
      whiteSpace: isExpandButton ? 'break-spaces' : 'unset',
      textAlign: isExpandButton ? 'left' : 'unset',
    },
    '& .MuiListItemText-root': {
      flex: 0,
      minWidth: isExpandButton ? '151px' : 'auto',
      height: theme.spacing(3),
      alignItems: 'center',
      display: 'flex',
    },
  };
});

export const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'stylingVariant',
})(({ theme, stylingVariant, color, style: customStyle }) => {
  const { selectItemColor, selectItemBorderRadius, selectItemBgColor, selectItemPadding } = useStyleHelpers({
    theme,
    stylingVariant,
    color,
  });

  const style =
    customStyle !== null && customStyle !== undefined
      ? { ...customStyle }
      : { color: selectItemColor(), backgroundColor: selectItemBgColor() };
  return {
    padding: selectItemPadding(),
    borderRadius: selectItemBorderRadius(),
    ...style,
  };
});
