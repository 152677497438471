import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { isLocalHost } from 'services/localhost';
import { clearSortsAndFilters } from 'features/queue/slice';
import { logout } from 'store/userSlice';
import { logout as logoutFromCore } from 'services/userService';
import { clearStorage } from './utils';

export default function Logout() {
  const dispatch = useDispatch();
  const router = useRouter();
  const from = router.latestLocation.search?.from;
  const fromParam = from ? `?from=${encodeURIComponent(from)}` : '';

   const signOut = useCallback(async () => {
    dispatch(clearSortsAndFilters());
    if (!isLocalHost()) await logoutFromCore();
    clearStorage();
    dispatch(logout());
    // window?.Sentry?.setUser?.(null);
    setTimeout(() => {
      window.location.href = `/spaces/login${fromParam}`;
    });
  }, [dispatch, fromParam]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <></>;
}
