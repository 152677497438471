import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider, removeOldestQuery } from '@tanstack/react-query-persist-client';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { decompress } from 'lz-string';
import { CssBaseline } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import compressWorker from 'workerize-loader!./workers/compress.worker'; // eslint-disable-line import/no-webpack-loader-syntax
import queryClient, { cacheTime, throttleTime } from './remote-state/queryClient';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store/store';
import Theme from './features/theme';

const compressWorkerInstance = compressWorker();

const localStoragePersister = createAsyncStoragePersister({
  storage: window.localStorage,
  retry: removeOldestQuery,
  serialize: (data) => new Promise((resolve, reject) => {
    const handleMessage = (event) => {
      if (event.data.action === 'compressed') {
        compressWorkerInstance.removeEventListener('message', handleMessage); // Clean up
        resolve(event.data.data);
      } else {
        compressWorkerInstance.removeEventListener('message', handleMessage); // Clean up
        reject(Error('Compression failed'));
      }
    };

    compressWorkerInstance.addEventListener('message', handleMessage);
    compressWorkerInstance.compress({ data: JSON.stringify(data) });
  }),
  deserialize: (data) => JSON.parse(decompress(data)),
  throttleTime,
});

const persistBlacklist = [
  'srId',
  'templateData',
  'ticketLockStatus',
  'customer-logo',
  'workflows',
  'srAttachments',
  'defaultTemplate',
  'featureFlag',
  'journeyAuditLogsByType',
  'messagesInitialData',
  'userDetailsByName',
  'userDetailsById',
  'closureInformation',
  'ticketSummary',
  'ticketPermissions',
  'templatesListTotalSize',
  'ticketLockStatus',
  'categoriesAttr',
];

const persistOptions = {
  persister: localStoragePersister,
  maxAge: cacheTime, // should be equal or lower than cacheTime
  // hydrateOptions: {},
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => !persistBlacklist.includes(queryKey[0]),
  },
};

async function initializeDataDog() {
  let envName = "UndefinedEnv";
  try {
    const response = await fetch('/spaces/DataDog.json');
    const data = await response.json();
    if (data) {
      envName = data.ENVIRONMENT_NAME;
      console.log("DataDog env name:", envName);
    }
  } catch (e) {
    console.log("Failed fetching DataDog environment name", e);
  }

  // Initialize DataDog with the fetched environment name
  datadogRum.init({
    applicationId: 'b53eaae0-cedd-4812-a85c-151710c18390',
    clientToken: 'pub92b6ca01cdbc866410a3e19b8f2a7712',
    site: 'datadoghq.com',
    service: 'spaces-client',
    env: envName,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

initializeDataDog();

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <Theme>
          <CssBaseline />
          <App />
        </Theme>
      </PersistQueryClientProvider>
    </PersistGate>
  </Provider>
);

//If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
