import { useMemo } from 'react';
import { getIsExistingSr } from 'common/utils/srUtils';
import { ReactComponent as PrevIcon } from 'images/icons/prev.svg';
import { ReactComponent as NextIcon } from 'images/icons/next.svg';
import Tooltip from 'common/components/tooltip';
import useTexts from '../useTexts';

function NextOrPrevSR({ srId }) {
  const { nextTicketText, prevTicketText } = useTexts();
  const isExistingSR = getIsExistingSr(srId);

  const loadedSrIds = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem('loadedSrIds')) || [];
    } catch {
      return [];
    }
  }, []);

  const currentSrIdIndex = loadedSrIds.findIndex((loadedSrId) => loadedSrId === srId);
  const isNextDisable = currentSrIdIndex === loadedSrIds.length - 1;
  const isPrevDisable = currentSrIdIndex === 0;

  const handleClickPrev = () => {
    window.location.href = `?id=${loadedSrIds[currentSrIdIndex - 1]}`;
  };

  const handleClickNext = () => {
    window.location.href = `?id=${loadedSrIds[currentSrIdIndex + 1]}`;
  };

  return (
    <>
      {Boolean(loadedSrIds?.length) && isExistingSR && currentSrIdIndex !== -1 && (
        <div className="next-or-prev-sr">
          <Tooltip
            text={
              <button onClick={handleClickPrev}
                      disabled={isPrevDisable}
                      data-testid="prev-sr"
              >
                <PrevIcon />
              </button>
            }
            title={prevTicketText}
            placement="top"
            disableInteractive={isPrevDisable}
            disableHoverListener={isPrevDisable}
            disableTouchListener={isPrevDisable}
          />
          <Tooltip
            text={
              <button onClick={handleClickNext}
                      disabled={isNextDisable}
                      data-testid="next-sr"
              >
                <NextIcon />
              </button>
            }
            title={nextTicketText}
            placement="top"
            disableInteractive={isNextDisable}
            disableHoverListener={isNextDisable}
            disableTouchListener={isNextDisable}
          />
        </div>
      )}
    </>
  );
}

export default NextOrPrevSR;
