import { useEffect, useState, useCallback } from 'react';
import Carousel from 'react-material-ui-carousel';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import { ReactComponent as ZoomInIcon } from 'images/icons/ZoomInIcon.svg';
import { ReactComponent as ZoomOutIcon } from 'images/icons/ZoomOutIcon.svg';
import { ReactComponent as CrossIcon } from 'images/icons/CrossIcon.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/DownloadIcon.svg';
import { ReactComponent as TrashBinIcon } from 'images/icons/TrashBinIcon.svg';
import { useGeneratedPresignedUrlByAttachmentId } from 'remote-state/attachments';

import { saveFile } from 'common/utils/files';

import CarouselItem from './CarouselItem';
import {
  StyledFileManagerContainer,
  StyledCarouselContainer,
  StyledEditFileContainer,
  StyledZoomContainer,
} from './styles';
import { CONFIG, MAX_ZOOM_AMOUNT, MIN_ZOOM_AMOUNT } from './constants';

const FilesPreview = ({ filesData, deleteFileEnabled, selectedFileIndex, onFileRemove, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(selectedFileIndex);
  const attachmentId = filesData[activeIndex].attachmentId;
  const fileId = filesData[activeIndex].fileId;
  const {
    data: { downloadUrl: presignedUrl, previewUrl },
  } = useGeneratedPresignedUrlByAttachmentId(attachmentId, fileId);

  const isScreenCapture = () => filesData[activeIndex].isScreenCapture;

  const handleFileChange = (currentIndex) => {
    setActiveIndex(currentIndex);
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleFileSave = () => {
    const downloadUrl = isScreenCapture() ? filesData[activeIndex].src : presignedUrl;

    saveFile({ url: downloadUrl, fileName: filesData[activeIndex].fileName });
  };

  const handleFileRemove = () => {
    onFileRemove(filesData[activeIndex].removeParams);
  };

  const handleBackdropClick = useCallback(
    (event) => {
      const carouselItem = document.getElementsByClassName('carousel-item')?.[activeIndex];
      const zoomContainer = document.getElementsByClassName('zoom-container')?.[0];
      const downloadBtn = document.querySelector('[data-testid="file-preview-download-button"]');
      const shouldClose = ![carouselItem, zoomContainer, downloadBtn].some((element) =>
        element?.contains(event.target),
      );

      if (shouldClose) {
        onClose(false);
      }
    },
    [activeIndex, onClose],
  );

  const editFileButtons = {
    removeFile: { key: 'removeFile', dataTestId: 'remove', icon: TrashBinIcon, handleClick: handleFileRemove },
    downloadFile: { key: 'downloadFile', dataTestId: 'download', icon: DownloadIcon, handleClick: handleFileSave },
    closePreview: { key: 'closePreview', dataTestId: 'close', icon: CrossIcon, handleClick: handleClose },
  };

  const editButtons = deleteFileEnabled
    ? [editFileButtons.removeFile, editFileButtons.downloadFile, editFileButtons.closePreview]
    : [editFileButtons.downloadFile, editFileButtons.closePreview];

  const [zoomAmount, setZoomAmount] = useState(1);
  useEffect(() => {
    setZoomAmount(1);
  }, []);
  const handleZoomIn = () => {
    if (zoomAmount < MAX_ZOOM_AMOUNT) setZoomAmount((prevZoomAmount) => prevZoomAmount + 0.1);
  };

  const handleZoomOut = () => {
    if (zoomAmount > MIN_ZOOM_AMOUNT) setZoomAmount((prevZoomAmount) => prevZoomAmount - 0.1);
  };
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} onClick={handleBackdropClick}>
      <StyledFileManagerContainer>
        <StyledEditFileContainer>
          {editButtons.map((item) => (
            <button
              className="edit-file-button"
              key={item.key}
              data-testid={`file-preview-${item.dataTestId}-button`}
              onClick={item.handleClick}
            >
              <item.icon />
            </button>
          ))}
        </StyledEditFileContainer>
        <StyledCarouselContainer>
          <Carousel
            {...CONFIG}
            index={selectedFileIndex}
            navButtonsAlwaysInvisible={filesData.length < 2}
            onChange={handleFileChange}
          >
            {filesData.length > 0 &&
              filesData.map((file, index) => (
                <CarouselItem
                  index={index}
                  zoomAmount={zoomAmount}
                  key={file.key}
                  file={file}
                  isVisible={index === activeIndex}
                  previewUrl={previewUrl}
                />
              ))}
          </Carousel>
        </StyledCarouselContainer>
      </StyledFileManagerContainer>
      <StyledZoomContainer className="zoom-container" visible={activeIndex}>
        <ZoomOutIcon
          className="zoom-icon"
          onClick={handleZoomOut}
          aria-label={`uploadedFile-${activeIndex}-zoom-out`}
        />
        <ZoomInIcon className="zoom-icon" onClick={handleZoomIn} aria-label={`uploadedFile-${activeIndex}-zoom-in`} />
      </StyledZoomContainer>
    </Backdrop>
  );
};

export default FilesPreview;

FilesPreview.propTypes = {
  filesData: PropTypes.array,
  deleteFileEnabled: PropTypes.bool,
  selectedFileIndex: PropTypes.number,
  onFileRemove: PropTypes.func,
  onClose: PropTypes.func,
};

FilesPreview.defaultProps = {
  filesData: [],
  deleteFileEnabled: false,
  selectedFileIndex: -1,
  onFileRemove: () => {},
  onClose: () => {},
};
