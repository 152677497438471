import { useState, useEffect, useRef } from 'react';
import { ReactComponent as ToocanOutlinedIcon } from 'images/icons/generativeAi/toocan_outlined.svg';
import { ReactComponent as ThumbsDownOutlinedIcon } from 'images/icons/generativeAi/thumbs_down_outlined.svg';
import { ReactComponent as ThumbsDownFilledIcon } from 'images/icons/generativeAi/thumbs_down_filled.svg';
import { ReactComponent as ThumbsUpOutlinedIcon } from 'images/icons/generativeAi/thumbs_up_outlined.svg';
import { ReactComponent as ThumbsUpFilledIcon } from 'images/icons/generativeAi/thumbs_up_filled.svg';
import { IconButton } from '@mui/material';
import mediumYellowStars from 'images/icons/generativeAi/yellow_stars_24x24.gif';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants/index';
import { StyledSummaryPopup } from './StyledSummaryPopup';
import { useTexts } from './useTexts';
import { usePostTicketSummaryFeedback, useTicketSummaryByIdQuery } from './summaryPopupHooks';
import { Sentiment } from './Sentiment';

export const SummaryPopup = ({ title: propsTitle, accountId, userId, srId: propsSrId, popperRef }) => {
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false);
  const [summary, setSummary] = useState(null);
  const [sentiment, setSentiment] = useState(null);
  const [srId] = useState(propsSrId);
  const { isInitialLoading, isFetching, status, data } = useTicketSummaryByIdQuery(srId);
  const { mutate } = usePostTicketSummaryFeedback();
  const [title, setTitle] = useState(propsTitle);
  const texts = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();
  const ref = useRef({ firstRender: true });

  useEffect(() => {
    if (!isFetching) {
      if (status === 'success') {
        setSummary(data.summary);
        setTitle((currTitle) => currTitle || data.title);
        setSentiment(data.sentiment);
        if (ref.current.firstRender) {
          ref.current.firstRender = false;
          pendoTrackEvents(PENDO_TRACK_EVENTS.TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED, {
            srId,
          });
        }
      } else if (status === 'error') {
        setSummary(texts.summaryNotAvailableCheckBackSoon);
        if (ref.current.firstRender) {
          ref.current.firstRender = false;
          pendoTrackEvents(PENDO_TRACK_EVENTS.TICKET_SUMMARY_ERROR, {
            srId,
          });
        }
      }
    }
  }, [status, isFetching, data, texts.summaryNotAvailableCheckBackSoon, popperRef, srId, pendoTrackEvents]);

  useEffect(() => {
    if (!isInitialLoading && popperRef?.current) {
      popperRef.current.update();
    }
  }, [popperRef, isInitialLoading, pendoTrackEvents]);

  const handleClick = (e, feedback) => {
    e.stopPropagation();

    if (feedback === 'good') {
      setThumbsUpClicked(true);
    } else if (feedback === 'bad') {
      setThumbsDownClicked(true);
    }

    mutate({
      accountId,
      userId,
      srId,
      summary,
      feedback,
    });
  };

  const isFeedbackDisabled = thumbsUpClicked || thumbsDownClicked;

  return (
    <StyledSummaryPopup>
      <div className="header-container" data-testid="header">
        <ToocanOutlinedIcon className="header-icon" />
        <span data-testid="title">{title}</span>
      </div>
      <div className={`content ${isInitialLoading ? 'loading' : ''}`} data-testid="content">
        {isInitialLoading ? (
          <>
            <img className="loading-stars-icon" data-testid="loader" src={mediumYellowStars} alt="" />
            {texts.loading}
          </>
        ) : (
          <>
            <div>{summary}</div>
            {sentiment && (
              <div className="sentiment-container" data-testid="sentiment">
                <span className="sentiment-label">{texts.sentimentLabel}</span>
                <Sentiment sentiment={sentiment} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="feedback-controls-container">
        <span className="footer-text">{texts.poweredByJaySysaidGenerativeAi}</span>
        <IconButton
          data-testid="thumbs-up"
          className="thumbs-up-button"
          size="small"
          onClick={(e) => handleClick(e, 'good')}
          disabled={isFeedbackDisabled}
        >
          {thumbsUpClicked ? <ThumbsUpFilledIcon /> : <ThumbsUpOutlinedIcon />}
        </IconButton>
        <IconButton
          data-testid="thumbs-down"
          size="small"
          onClick={(e) => handleClick(e, 'bad')}
          disabled={isFeedbackDisabled}
        >
          {thumbsDownClicked ? <ThumbsDownFilledIcon /> : <ThumbsDownOutlinedIcon />}
        </IconButton>
      </div>
    </StyledSummaryPopup>
  );
};
