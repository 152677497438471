import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiTextField, { textFieldClasses } from '@mui/material/TextField';

const StyledDatePickerIgnoreProps = ['isCustomDateFilter', 'disabled', 'fullwidth'];
export const StyledDatePicker = styled(Box, {
  shouldForwardProp: (prop) => !StyledDatePickerIgnoreProps.includes(prop),
})(({ theme, isCustomDateFilter, fullwidth, disabled, isEmptyRequiredValue }) => {
  let maxWidth = 'initial';
  let buttonBaseBackground = theme?.palette?.background?.bg_hover_main;
  if (!isCustomDateFilter) {
    maxWidth = '236px';
  } else if (isCustomDateFilter) {
    maxWidth = '200px';
  }

  if (fullwidth) {
    maxWidth = '100%';
  }
  if (disabled) {
    buttonBaseBackground = 'transparent';
  }
  if (isEmptyRequiredValue && !disabled) {
    buttonBaseBackground = null;
  }

  return {
    display: 'flex',
    alignItems: !isCustomDateFilter ? 'flex-start' : 'center',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    flexDirection: 'column',
    margin: '0px',
    width: '100%',
    maxWidth,
    '&:hover': {
      borderRadius: `${theme?.shape?.border?.borderRadius} !important`,
      '& > .MuiBox-root > .MuiButtonBase-root': {
        backgroundColor: buttonBaseBackground,
      },
    },
  };
});

const DatePickerContainerIgnoreProps = ['isCustomDateFilter', 'source'];
export const DatePickerContainer = styled(Box, {
  shouldForwardProp: (prop) => !DatePickerContainerIgnoreProps.includes(prop),
})(({ source }) => ({
  position: 'relative',
  width: '100%',
  alignSelf: source === 'ticket' ? 'start' : 'center',
  '& > :first-of-type': {
    width: '100%',
    marginLeft: source === 'ticket' ? '-6px' : '0',
    '& > :first-of-type': {
      width: 'auto',
    },
  },
  '& .MuiPickerStaticWrapper-root': {
    position: 'absolute',
  },
}));

const StyledMuiTextFieldIgnoreProps = [
  'unread',
  'error',
  'ticket',
  'isNewField',
  'isCustomDateFilter',
  'isTemplatePage',
];
export const StyledMuiTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => !StyledMuiTextFieldIgnoreProps.includes(prop),
})(({ theme, unread, error, isCustomDateFilter, isTemplatePage, ticket, isNewField }) => {
  let color = theme?.palette?.text?.secondary_txt;
  let hoveredBackground = theme?.palette?.background?.hover;
  let background = 'transparent';
  let height = 'auto';
  let textFieldPadding = '4px 8px';

  if (isTemplatePage) {
    height = '18px';
    textFieldPadding = '6px 8px';
  } else if (!isCustomDateFilter) {
    height = '16px';
  }

  if (unread) {
    color = theme?.palette?.text?.title;
  }

  if (ticket) {
    color = theme?.palette?.text?.txt_default;
  }

  if (isNewField) {
    background = theme?.palette?.background?.bg_new_input_text;
    hoveredBackground = '#D5E4F2';
  }

  if (error) {
    color = theme?.palette?.labels?.bt_red_reg;
    hoveredBackground = theme?.palette?.labels?.bt_red_light;
    background = theme?.palette?.labels?.bt_red_ligh;
  }

  return {
    [`&.${textFieldClasses.root}`]: {
      borderRadius: `${theme?.shape?.border?.borderRadius} !important`,
      '.Mui-disabled': {
        fontFamily: unread ? theme?.fonts?.robotoBold : theme?.fonts?.robotoRegular,
        color,
        textFillColor: error ? color : theme?.palette?.text?.txt_default,
      },
      '&:hover': {
        background: hoveredBackground,
      },
      '&:hover .MuiInput-input': {
        color: error && `${color} !important`,
      },
      '.MuiInput-input': {
        color: error && color,
        padding: !isCustomDateFilter ? '2px 0' : '2px 0 0 0',
        height,
        width: isCustomDateFilter ? '200px' : '',
        textOverflow: 'ellipsis',
      },
      '&:hover .clearIndicatorDirty': {
        visibility: 'visible',
      },
      '.clearIndicatorDirty': {
        visibility: isCustomDateFilter ? 'visible' : 'hidden',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '.MuiInput-root': {
        width: '100%',
        padding: textFieldPadding,
        cursor: 'inherit',
        fontSize: '14px',
        fontFamily: unread ? theme?.fonts?.robotoBold : theme?.fonts?.robotoRegular,
        color,
        background,
        borderRadius: '4px',
        '&:hover': {
          background: error && `${hoveredBackground} !important`,
          color: error && `${color} !important`,
        },
      },
    },
  };
});
