import { useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import MultiSelectField from 'common/components/controls/MultiSelectField';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import { useGetAllOptions } from './useMultiSelectAIAttributes';

const multiSelectPlaceholder = '--';

export default function MultiSelectListAI({
  name,
  label,
  disabled,
  readOnly,
  keyExpr = 'id',
  displayExpr = 'value',
  isTemplatePage,
  ...restProps
}) {
  const { control, onSubmit } = useFormContext() || {};
  const { field } = useController({ name, control });
  const allOptions = useGetAllOptions(name);
  const [selectedOptions, setSelectedOptions] = useState((field?.value || '').split(',').map(Number));

  const handleSelectionChange = (selectedValues) => {
    const newChosenValues = selectedValues?.toString();
    const oldChosenValues = selectedOptions?.toString();
    const fieldName = name;
    onSubmit({ oldValue: oldChosenValues, newValue: newChosenValues , fieldName });
    field?.onChange(newChosenValues);
    setSelectedOptions(selectedValues);
  };

  return (
    <div style={{ width: '-webkit-fill-available' }}>
      <div className="multi-select-wrapper">
        <FieldName
          fieldId={field?.fieldId}
          value={displayExpr}
          name={allOptions?.displayName}
          hintText={field?.hint && field?.hintText}
          {...restProps}
        />

        {allOptions?.values?.length > 0 && (
          <MultiSelectField
            itemList={allOptions?.values}
            selectedItemList={selectedOptions}
            isTemplatePage={isTemplatePage}
            handleSelectItems={handleSelectionChange}
            captionString={displayExpr}
            keyString={keyExpr}
            fieldPlaceholder={multiSelectPlaceholder}
            readOnly={readOnly}
            disabled={disabled}
            isWorkFlowMultiList
            {...restProps}
          />
        )}
      </div>
    </div>
  );
}
