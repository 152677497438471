import { useCallback, useEffect, lazy, Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { configureAbly, useChannel } from '@ably-labs/react-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from 'common/components/errorBoundary';
import EscalationPrompt from 'common/components/escalationPrompt';
import PendingComponentLoader from 'common/components/pendingComponentLoader';
import { getShowNavBar, isMobileUserAgent, removeCookie } from 'common/utils/utils';
import { QUERIES_KEYS } from 'constant';
import { APP_CONSTANTS } from 'constants/app';
import { AUTH_CONSTANTS } from 'constants/auth';
import { CHANNEL_MESSAGE_TYPES } from 'constants/common';
import NavigationBar from 'features/navBar';
import { COMPANY_FIELD_KEY, SESSION_STORAGE } from 'features/queue/constants';
import {
  selectActiveView,
  selectColumnsOrder,
  selectDefaultView,
  selectOriginalView,
  setActiveView,
  setColumnsOrder,
  setDefaultView,
  setOriginalView,
} from 'features/queue/slice';
import { getViewInitializedColumnConfig } from 'features/queue/utils';
import { BASIC_EDITION } from 'features/settings/constants';
import { useApplicationData } from 'remote-state/applicationHooks';
import { handleLocalHostUserDetails, isLocalHost } from 'services/localhost';
import { details, selectActiveUser } from 'store/userSlice';
import { clearStorage } from 'features/Logout/utils';
import { getUserMobileLink } from '../../services/userService';
import ReloadPagePrompt from './reloadPagePrompt';
import { toggle, selectIsChatbotOpen } from './slice';
import { StyledContainer } from './style';

const AdminChatbot = lazy(() => import('features/adminChatbot'));

const PENDO_RESTRICTED_ACCOUNTS = [
  'automationbatch1'
];

const editions = new Map([
  [0, 'Basic Edition'],
  [2, 'Full Edition'],
]);

async function isMobileUserAgentRedirected(userAgent, userRole) {
  if (isMobileUserAgent(userAgent) && userRole === AUTH_CONSTANTS.ADMIN) {
    const res = await getUserMobileLink();
    if (res?.mobileAppServerUrl?.length > 0) {
      window.location.href = res.mobileAppServerUrl;
      return true;
    }
  }
  return false;
}

function getBrowserType() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    return 'Internet Explorer';
  }
  if (userAgent.indexOf('Edge') !== -1) {
    return 'Microsoft Edge';
  }
  if (userAgent.indexOf('Firefox') !== -1) {
    return 'Mozilla Firefox';
  }
  if (userAgent.indexOf('Chrome') !== -1) {
    return 'Google Chrome';
  }
  if (userAgent.indexOf('Safari') !== -1) {
    return 'Apple Safari';
  }
  if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    return 'Opera';
  }
  return 'Unknown browser';
}

const handleBeforeLoad = async ({ currentUser, dispatch, isAiEnabled, edition, defaultUi }) => {
  if (isLocalHost()) {
    if (currentUser?.isAuth) {
      if (window.permissionInitialized) return;
      window.permissionInitialized = true;
    } else {
      handleLocalHostUserDetails(dispatch);
    }
    return;
  }
  const isClassicRedirected = localStorage.getItem(APP_CONSTANTS.IS_CLASSIC_REDIRECTED);
  if (defaultUi === 'CLASSIC' && !isClassicRedirected) {
    removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
    localStorage.setItem(APP_CONSTANTS.IS_CLASSIC_REDIRECTED, 'true');
    window.open(window.location.origin, '_self', 'noreferrer');
    return;
  }
  if (currentUser?.roles[0] === AUTH_CONSTANTS.END_USER) {
    window.location.href = '/servicePortal';
    return;
  }

  const isQAMode =
    localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) || localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) === null;

  if (isQAMode === true || isQAMode === "true" || PENDO_RESTRICTED_ACCOUNTS.includes(localStorage.getItem('account_id'))) {
    console.log("Pendo Guides are turned off");
    try {
      window.pendo?.stopGuides?.();
    } catch(e){
      console.log('stopGuides err: ', e);
    }
  }

  if (currentUser?.isAuth) {
    window.pendo?.initialize({
      visitor: {
        id: `${localStorage.getItem('account_id')}_${currentUser?.userId}`,
        visitorId: `${localStorage.getItem('account_id')}_${currentUser?.userId}`,
        accountId: `${localStorage.getItem('account_id')}`,
        userLanguage: currentUser?.language,
        calculatedRole: currentUser?.calculatedRole,
        browserType: getBrowserType(),
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        deviceMemory: navigator?.deviceMemory,
      },
      account: {
        id: localStorage.getItem('account_id'),
        isSpaces: true,
        isQAMode,
        isOnDemand: true,
        edition: editions.get(edition) ?? 'Unknown',
        isAI: isAiEnabled,
      },
    });
    if (window.permissionInitialized) return;
    window.permissionInitialized = true;
  }

  isMobileUserAgentRedirected(navigator.userAgent, currentUser?.roles[0]);
};

const PrivatePagesComponentWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectActiveUser);
  const userDetails = useSelector(details);
  const { isFetched, isFetching } = useApplicationData(QUERIES_KEYS.APPLICATION_DATA, { forceCall: true });
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const { data: defaultUi } = useApplicationData(QUERIES_KEYS.DEFAULT_UI);
  const showContent = isLocalHost() ? isFetched : isFetched && !isFetching;

  useEffect(() => {
    handleBeforeLoad({ currentUser, userDetails, dispatch, isAiEnabled, edition, defaultUi });
  }, [currentUser, userDetails, dispatch, isAiEnabled, edition, defaultUi]);

  return showContent ? <>{children}</> : <PendingComponentLoader />;
};

const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
const QUEUE_CHANNEL_NAME = accountId;
const targetChannel = localStorage.getItem(APP_CONSTANTS.ACCOUNT_TARGET);

export default function LoggedInRoutesPanel({ children }) {
  const dispatch = useDispatch();
  const [isReloadPromptOpen, setReloadPromptOpen] = useState(false);
  useApplicationData(QUERIES_KEYS.APPLICATION_DATA);
  const router = useRouter();
  const activeView = useSelector(selectActiveView);
  const defaultView = useSelector(selectDefaultView);
  const originalView = useSelector(selectOriginalView);
  const columnsOrder = useSelector(selectColumnsOrder);
  const isChatbotOpen = useSelector(selectIsChatbotOpen);
  const queryClient = useQueryClient();
  const [menuExpanded, setMenuExpanded] = useState(
    JSON.parse(localStorage.getItem(APP_CONSTANTS.IS_MENU_EXPANDED)) === true,
  );
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const isBasicEdition = edition === BASIC_EDITION;
  const isLocalEnvironment = isLocalHost();
  configureAbly({
    autoConnect: !isLocalEnvironment, // If working locally with Ably sockets enabled - comment this line out!
    authUrl: '/spaces/api/authentication/websocket/token',
    // authUrl: 'websocket/token', // for dev only
    recover(_, cb) {
      cb(true);
    },
  });

  useChannel(targetChannel, ({ name, data }) => {
    setReloadPromptOpen(true);
    console.log('Websocket Target Channel: useChannel event commited', { target: targetChannel, name, data });
  });

  useChannel(QUEUE_CHANNEL_NAME, ({ name, data }) => {
    console.log('Websocket LoggedIn: useChannel event commited', { name, data });
    if (name === CHANNEL_MESSAGE_TYPES.UPDATE_SETTINGS) {
      //We clean attributes with timeout because Wescoket works much faster than settings changes getting applied on backend.
      //In that case we are not getting updated values on attributes but old one.
      setTimeout(() => {
        switch (data?.type) {
          case 'defaultTemplate':
            queryClient.invalidateQueries({ queryKey: ['templatesList'] });
            queryClient.invalidateQueries({ queryKey: ['defaultTemplate'] });
            break;
          case 'applicationData':
            queryClient.invalidateQueries([QUERIES_KEYS.APPLICATION_DATA]);
            break;
          case 'srSubTab':
            queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.ATTRIBUTES, data?.listName] });
            localStorage.setItem('cacheKey', String(Math.random()));
            queryClient.invalidateQueries({ queryKey: ['fieldAttribute'] });
            break;
          default:
            localStorage.setItem('cacheKey', String(Math.random()));
            queryClient.invalidateQueries({ queryKey: ['fieldAttribute'] });
            break;
        }
      }, 2000);
    }
  });

  const showNavBar = getShowNavBar(router);

  const toggleNavBarCollapse = () => {
    localStorage.setItem(APP_CONSTANTS.IS_MENU_EXPANDED, !menuExpanded);
    setMenuExpanded(!menuExpanded);
    setTimeout(() => dispatch(toggle()));
  };

  const clearStorageByEdition = useCallback(() => {
    if (columnsOrder) {
      dispatch(setColumnsOrder(columnsOrder.filter((column) => column.fieldName !== COMPANY_FIELD_KEY)));
    }

    let columnsConfig;

    if (defaultView?.columnsConfig) {
      columnsConfig = getViewInitializedColumnConfig(defaultView.columnsConfig);
      dispatch(
        setDefaultView((prev) => ({
          ...prev,
          columnsConfig,
        })),
      );
    }

    if (originalView?.columnsConfig) {
      columnsConfig = getViewInitializedColumnConfig(originalView.columnsConfig);

      dispatch(
        setOriginalView((prev) => ({
          ...prev,
          columnsConfig,
        })),
      );
    }

    if (activeView?.columnsConfig) {
      columnsConfig = getViewInitializedColumnConfig(activeView.columnsConfig);

      dispatch(
        setActiveView((prev) => ({
          ...prev,
          columnsConfig,
        })),
      );
    }
  }, [columnsOrder, activeView, defaultView, originalView, dispatch]);

  useEffect(() => {
    const storedEdition = sessionStorage.getItem(SESSION_STORAGE.edition);
    if (isBasicEdition && (storedEdition === null || Number(edition) !== Number(storedEdition))) {
      clearStorageByEdition();
    }
    if (!storedEdition || Number(edition) !== Number(storedEdition)) {
      sessionStorage.setItem(SESSION_STORAGE.edition, edition);
    }
  }, [edition, isBasicEdition, clearStorageByEdition]);

  // useEffect(() => {
  //   if (userAccount?.username && userAccount?.userId) {
  //     window?.Sentry?.setUser?.({ username: userAccount?.username, id: userAccount?.userId });
  //   }
  // }, [userAccount?.username, userAccount?.userId]);

  const handleReloadPromptClick = () => {
    setReloadPromptOpen(false);
    clearStorage(true, true);
    queryClient.resetQueries();
    setTimeout(() => {
      window.location.reload(true);
    }, 100);
  };

  const AdminChatbotFallback = () => (
    <div style={{ width: '30%' }}>
      <PendingComponentLoader />
    </div>
  );

  return (
    <PrivatePagesComponentWrapper>
      <ReloadPagePrompt onClick={handleReloadPromptClick} open={isReloadPromptOpen} />
      <ErrorBoundary>
        {showNavBar ? (
          <>
            <NavigationBar toggleNavBarCollapse={toggleNavBarCollapse} navBarCollapsed={!menuExpanded} />
            <StyledContainer className={isChatbotOpen ? 'chatbot-open' : ''}>
              {children}
              {isChatbotOpen && (
                <Suspense fallback={<AdminChatbotFallback />}>
                  <AdminChatbot />
                </Suspense>
              )}
            </StyledContainer>
          </>
        ) : (
          { children }
        )}
        <EscalationPrompt />
      </ErrorBoundary>
    </PrivatePagesComponentWrapper>
  );
}
