import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledFieldHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '4px 0',
  maxHeight: '92px',
  height: '16px',
}));

export const StyledFieldLabel = styled('span')(({ theme }) => ({
  fontSize: theme.fontSize.small,
  color: theme.palette.text.secondary_txt,
}));

export const MultiSelectFieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['isDropdownOpen', 'error', 'isTemplatePage', 'isSrPage', 'disabled', 'readOnly'].includes(prop),
})(({ theme, isDropdownOpen, error, isTemplatePage, isSrPage, disabled, readOnly }) => {
  let background = 'unset';
  let placeholderColor = 'initial';
  if (isDropdownOpen) {
    background = theme?.palette?.background?.bg_hover_main;
  } else if (!isDropdownOpen && error) {
    background = theme.palette.bt_red_light.main;
    placeholderColor = `${theme.palette.labels.bt_red_reg} !important`;
  }

  return {
    backgroundColor: `${background} !important`,
    position: 'relative',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',

    '& .empty-multi-select-chip-placeholder': {
      color: placeholderColor,
    },
    '& span[data-error-tooltip=true], .warning-icon': isDropdownOpen && {
      display: 'none',
    },
    '&:hover': !isDropdownOpen && !disabled && !readOnly && {
      backgroundColor:
        !isTemplatePage && error
          ? `${theme.palette.labels.bt_red_mid} !important`
          : `${theme?.palette?.background?.bg_hover_main} !important`,
      '& .empty-multi-select-chip-placeholder': {
        color: error ? `${theme.palette.labels.bt_red_dark} !important` : 'initial',
      },
      // TODO remove this when onClick on whole multi select field is fixed (SR page)
      '& span[data-error-tooltip=true], .warning-icon': isSrPage && {
        display: 'none',
      },
      '& .warning-icon *': {
        fill: theme.palette.labels.bt_red_dark,
      },
    },
  };
});

export const StyledMultiSelectField = styled('div')(({ theme }) => ({
  height: '100%',
  padding: '5px 4px 5px 0',
  border: '1px solid',
  borderColor: theme?.palette?.grey?.divider_grey_v1,
  borderRadius: '4px',
}));

export const StyledListWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'listHeight' && prop !== 'isDropdownOpen',
})(({ theme, listHeight, isDropdownOpen }) => {
  const overflowY = listHeight < 72 ? 'hidden' : 'auto';
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#FFFFFF',
    flexDirection: 'row !important',
    flexWrap: 'wrap !important',
    listStyle: 'none',
    padding: isDropdownOpen ? '0 28px 0 8px !important' : '0 28px 0 8px',
    height: 'auto',
    width: '100%',
    minHeight: '22px',
    maxHeight: '88px',
    rowGap: '8px',
    columnGap: '8px',
    overflowY,
    '.MuiButtonBase-root': {
      width: 'auto !important',
    },
    ...theme.customScrollbar,
  };
});

export const StyledMultiSelectLabel = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isError',
})(({ theme, isError }) => {
  let color = theme.palette.text.title_txt;
  if (isError) {
    color = theme.palette.labels.bt_red_reg;
  }
  return {
    position: 'absolute',
    fontSize: theme.fontSize.main,
    color,
  };
});

export const StyledPlusWrapper = styled('span')(({ listHeight, disabled, readOnly }) => {
  const right = listHeight >= 98 ? '12px' : '8px';
  const pointerType = disabled || readOnly ? 'not-allowed' : 'pointer';
  return {
    position: 'absolute',
    cursor: pointerType,
    height: '20px',
    right,
    top: '7px',
  };
});

export const StyledDropDownWrapper = styled('div')(() => ({
  position: 'absolute',
  marginTop: '9px',
  width: '100%',
  zIndex: '999999',
  '& > div': {
    width: '100%',
    '& > div:first-of-type': {
      width: 'auto',
      '& > div:first-of-type': {
        width: '100%',
      },
    },
    '& > div:last-of-type(3)': {
      maxHeight: '246px !important',
      overflow: 'hidden !important',
      minHeight: '48px !important',
      '& > div:first-of-type': {
        maxHeight: '246px !important',
      },
    },
    '.MuiButtonBase-root': {
      '.MuiListItemText-root': {
        width: '91% !important',
        '.MuiTypography-root': {
          width: '100% !important',
          maxWidth: 'none !important',
        },
      },
    },
  },
}));

export const StyledMultiSelectFieldInner = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .warning-icon': {
    marginRight: '6px',
  },
}));
