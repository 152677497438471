/* this hook will fetch custom value from the BE only incase it does not exist in the list */
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getFieldAttributes } from 'services/ticketService';
import {
  convertValue,
  convertValues,
} from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';
import { getWorkflowFieldAttributes } from 'features/resolutionPanel/middlePanel/Workflows/api/workflowService';
import { REQUEST_USER_FIELD_ID } from 'features/queue/grid/constants';
import {
  convertFromClientFieldId,
  fieldValueExists,
  getIsUserFieldByFieldId,
  getValueKeyByFieldId,
} from '../fieldUtils';
import { expandQueryKey, mergeArraysByKey } from '../utils';

export const FIELD_ATTRIBUTES_TYPE = 'field_attribute_type';

const supportedZeroValueListKeys = [];

function searchInCache({ listKey, customColumn, valueType, queryObject, queryClient }) {
  const fieldId = convertFromClientFieldId(listKey, customColumn);
  const requestKey = expandQueryKey([valueType, fieldId, customColumn], queryObject);
  return queryClient.getQueryData(requestKey);
}

function searchInList({ ignoreList, list, value, listKey }) {
  const convertedList = convertValues(list);
  let foundInList = null;
  // sometimes the list parameter just haven't got time to be updated so we're returning null until it does, and sometimes we intentially don't send the list and just search/update the cache so we're using ignoreList to distinguish between the cases
  if (!ignoreList) {
    if (!list?.length) {
      return null;
    }
    const keyString = getValueKeyByFieldId(listKey);

    foundInList = convertedList?.find((v) => String(v[keyString]) === String(value));
  }
  return foundInList;
}

async function searchInApi({ valueType, listKey, customColumn, queryObject, isWorkflowApi, queryClient }) {
  let response;
  const fieldId = convertFromClientFieldId(listKey, customColumn);
  if (valueType === FIELD_ATTRIBUTES_TYPE) {
    if (isWorkflowApi) {
      response = await getWorkflowFieldAttributes(fieldId, queryObject);
    } else {
      response = await getFieldAttributes(fieldId, queryObject);
    }
  } else {
    throw new Error('Unsupported value type: ', valueType);
  }
  const requestKey = expandQueryKey([valueType, fieldId, customColumn], queryObject);
  const responseValues = response?.values;
  const objectsToReturn = [];

  if (responseValues) {
    responseValues.forEach((responseValue) => {
      const objectToReturn = { ...convertValue(responseValue), fieldName: response.fieldName };
      queryClient.setQueryData(requestKey, () => objectToReturn);
      objectsToReturn.push(objectToReturn);
    });
  }
  return objectsToReturn;
}

async function findValue(params) {
  const {
    list,
    id,
    listKey,
    customColumn,
    valueType = FIELD_ATTRIBUTES_TYPE,
    isMultiple,
    isWorkflowApi,
    queryClient,
    ignoreList,
  } = params;

  const queryObject = {};
  let cachedValue;
  let foundInList;
  let valuesFromApi;

  if (list === null || (Array.isArray(list) && list.length === 0)) {
    return null;
  }

  if (!fieldValueExists({ value: id, fieldId: listKey })) {
    return null;
  }
  
  if (listKey === undefined || listKey === null) return null;
  if (supportedZeroValueListKeys.indexOf(listKey) === -1 && id === 0) return null;

  const isUserField = getIsUserFieldByFieldId(listKey);

  if (isUserField) {
    queryObject.usernames = isMultiple ? id.map((item) => encodeURIComponent(item)).join(',') : encodeURIComponent(id);
  } else if (isMultiple) {
    queryObject.ids = id;
  } else {
    queryObject.id = id;
  }

  if (customColumn) {
    queryObject.customColumn = customColumn;
  }

  if (isMultiple) {
    const objectsToReturn = [];
    const values = id;

    values.forEach((value, index) => {
      cachedValue = searchInCache({
        listKey,
        customColumn,
        valueType,
        queryObject: isUserField ? { username: queryObject.usernames?.[index] } : { id: queryObject.ids?.[index] },
        queryClient,
      });

      if (cachedValue) {
        objectsToReturn.push(cachedValue);
      } else {
        foundInList = searchInList({ ignoreList, list, listKey, value });
        if (foundInList) {
          objectsToReturn.push(foundInList);
        }
      }
    });
    if (objectsToReturn.length === values.length) {
      return objectsToReturn;
    }

    valuesFromApi = await searchInApi({ valueType, listKey, customColumn, queryObject, isWorkflowApi, queryClient });
    return mergeArraysByKey(objectsToReturn, valuesFromApi, 'id');
  }
  cachedValue = searchInCache({
    listKey,
    customColumn,
    valueType,
    queryObject: isUserField ? { username: queryObject.usernames?.[0] } : queryObject,
    queryClient,
  });

  if (cachedValue) {
    return cachedValue;
  }
  foundInList = searchInList({ ignoreList, list, listKey, value: id });
  if (foundInList) {
    return foundInList;
  }
  valuesFromApi = await searchInApi({ valueType, listKey, customColumn, queryObject, isWorkflowApi, queryClient });
  if (valuesFromApi.length > 0) {
    return valuesFromApi[0];
  }

  return null;
}

const valueIs = (value, values) => values.includes(value);

export const useGetListValueById = ({
  listKey,
  customColumn = false,
  list,
  id,
  isMultiple,
  isWorkflowApi,
  valueType,
  ignoreList,
}) => {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    let isMount = true;
    async function fetch() {
      let value = await findValue({
        id,
        list,
        listKey,
        customColumn,
        isMultiple,
        valueType,
        isWorkflowApi,
        queryClient,
        ignoreList,
      });
      //In some cases tickets are created by non user with e-mail message and because of that API wouldnt return user information in the the list.
      //In that case to show proper UI we have to imitate user information by only data that we have its e-mail.
      if (!value && id && listKey === REQUEST_USER_FIELD_ID) {
        value = {
          valueCaption: id,
          id,
          value: id,
        };
      }
      if (isMount) {
        setData(value);
        setLoaded((value !== null && id) || (value === null && valueIs(id, ['', 'none', 0, '0', undefined, null])) || id && listKey && value === null);
      }
    }
    fetch();
    return () => {
      isMount = false;
    };
  }, [listKey, customColumn, id, list, isMultiple, valueType, isWorkflowApi, queryClient, ignoreList]);

  return { data, loaded };
};
