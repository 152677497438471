import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { DropdownSelect, useDropdownControl } from 'common/components/dropdownSelect';
import MultiSelectField from 'common/components/controls/MultiSelectField';
import { mergeArraysByKey } from 'common/utils/utils';
import { getFieldAttributes } from 'services/ticketService';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import Tooltip from 'common/components/tooltip';
import { convertFromClientFieldId, fieldValueExists, getValueKeyByFieldName } from 'common/utils/fieldUtils';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import { selectForceShowValidationError } from 'store/fieldValidationsSlice';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { BarLoader } from 'features/queue/grid/defaultCellRender/SkeletonLoader';
import { convertValue } from './values-convert';
import FieldName from '../fieldName';
import { TicketBodySelectButton } from './style';
import useTexts from '../useTexts';
import { ReactComponent as WarningIcon } from '../../../../images/icons/warning_icon.svg';

const FieldSearchableDropdown = (props) => {
  const {
    sr,
    url,
    field,
    values,
    sortBy,
    disabled,
    required,
    urlParams,
    isMultiple,
    fieldValue,
    isNewField,
    displayName,
    tooltipText,
    defaultSelectText,
    hasMoreDetails,
    handleDirty,
    isTemplatePage,
    onChange,
    onFocus,
    updateFieldValidation,
  } = props;

  const forceShowError = useSelector(selectForceShowValidationError);
  const [listValues, setListValues] = useState(values);

  const { fieldId, fieldName, customColumn } = field;
  const { mandatoryFieldError } = useTexts();

  useEffect(() => {
    setListValues(values);
  }, [values]);

  const parsedFieldValue = useMemo(() => {
    let value = fieldValue;
    if (fieldValue) {
      if (isMultiple) {
        value = fieldValue.split(',');
      } else if (typeof fieldValue === 'object') {
        value = convertValue(fieldValue);
      }
    }
    return value;
  }, [fieldValue, isMultiple]);

  const keyString = getValueKeyByFieldName(fieldName);

  let valueId;
  if (isMultiple) {
    valueId = parsedFieldValue;
  } else if (typeof parsedFieldValue === 'object') {
    valueId = parsedFieldValue?.[keyString];
  } else {
    valueId = parsedFieldValue;
  }

  const { data: fieldValueData, loaded: islistValueLoaded } = useGetListValueById({
    listKey: fieldId,
    customColumn,
    list: listValues,
    id: valueId,
    isMultiple,
  });

  const fieldCaption = fieldValueData?.valueCaption;

  const options = useMemo(() => {
    if (!isMultiple || isEmpty(fieldValueData)) return listValues;
    if (Array.isArray(fieldValueData)) return mergeArraysByKey(listValues, fieldValueData, keyString);
    return listValues.includes(fieldValueData) ? listValues : [...listValues, fieldValueData];
  }, [isMultiple, listValues, fieldValueData, keyString]);

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      const selectedText = defaultSelectText || value;
      isValid = !(
        (isMultiple
          ? !fieldValueExists({ value: selectedText, fieldTypeId: SR_PANEL_CONSTANTS.MULTI_SELECT })
          : !selectedText) && required
      );
      if (updateFieldValidation) {
        updateFieldValidation(fieldName, required, isValid);
      }
      return isValid;
    },
    [required, defaultSelectText, fieldName, updateFieldValidation, isMultiple],
  );

  const { anchor, handleOpen, handleClose } = useDropdownControl(disabled, onFocus);

  useEffect(() => {
    checkAndUpdateValidationStatus(fieldValueData);
  }, [fieldValueData, checkAndUpdateValidationStatus]);

  const multiSelectPlaceholder = '--';

  const onSelectChange = useCallback(
    async (selectedValue, optionsMap) => {
      checkAndUpdateValidationStatus(selectedValue);
      if (optionsMap) setListValues(Object.values(optionsMap));
      const nullValueForField = fieldName === 'assetId' ? '' : null;
      let value = selectedValue == null ? nullValueForField : selectedValue;
      if (handleDirty) {
        handleDirty({
          [fieldName]: value,
        });
      }
      if (isMultiple) {
        value = selectedValue?.toString();
      } else {
        value = selectedValue ? selectedValue[keyString] : value;
      }
      onChange(value);
      handleClose();
    },
    [fieldName, isMultiple, keyString, checkAndUpdateValidationStatus, onChange, handleDirty, handleClose],
  );

  const handleClearValue = useCallback(() => {
    onSelectChange(null);
  }, [onSelectChange]);

  const getTooltipText = () => {
    let text = '';
    if (fieldCaption) {
      text = fieldCaption;
    } else if (defaultSelectText) {
      text = defaultSelectText;
    } else {
      text = '--';
    }
    return text;
  };

  const isEmptyField = !fieldValueExists({ value: isMultiple ? fieldValueData : fieldCaption, fieldId });
  const checkIsError = () => {
    const isInvalid = isEmptyField && required;
    const newFieldError = isNewField && isInvalid && forceShowError;
    const existingFieldError = !isNewField && isInvalid;
    return newFieldError || existingFieldError;
  };

  const showWarningIcon = () => {
    const isError = checkIsError();
    return (isError && !isNewField) || (isNewField && isEmptyField && isError);
  };

  const selection = useMemo(() => {
    if (!fieldValueData) return [];
    if (Array.isArray(fieldValueData)) {
      return fieldValueData.map((item) => item[keyString]);
    }
    return [fieldValueData[keyString]];
  }, [fieldValueData, keyString]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { query, customColumn }),
    }),
    [fieldId, customColumn],
  );

  const MenuProps = useMemo(() => ({ 'data-testid': `ticket-select-${fieldName}-menu` }), [fieldName]);

  return (
    <>
      {isMultiple ? (
        <div className="multi-select-wrapper">
          <FieldName
            required={required}
            hasMoreDetails={hasMoreDetails}
            url={url}
            urlParams={urlParams}
            fieldValue={parsedFieldValue}
            fieldId={field?.fieldId}
            isTemplatePage={isTemplatePage}
            name={displayName}
            hintText={field?.hint && field?.hintText}
          />
          <MultiSelectField
            itemList={options}
            required={required}
            selectedItemList={selection}
            isTemplatePage={isTemplatePage}
            handleSelectItems={onSelectChange}
            captionString="valueCaption"
            keyString={keyString}
            onFocus={onFocus}
            fieldPlaceholder={multiSelectPlaceholder}
            fieldId={field?.fieldId}
            customColumn={field?.customColumn}
            srType={sr?.srType}
            isNewField={isNewField}
            tooltipTitle={showWarningIcon() && mandatoryFieldError}
          />
        </div>
      ) : (
        <>
          <FieldName
            required={required}
            hasMoreDetails={hasMoreDetails}
            url={url}
            urlParams={urlParams}
            fieldValue={parsedFieldValue}
            fieldId={field?.fieldId}
            isTemplatePage={isTemplatePage}
            name={displayName}
            hintText={field?.hint && field?.hintText}
          />
          {!islistValueLoaded ? (
            <BarLoader />
          ) : (
            <>
              <TicketBodySelectButton
                hasTextStyles={Boolean(fieldCaption)}
                hasValues={!!listValues.length}
                onClick={handleOpen}
                anchor={!!anchor}
                disabled={disabled}
                className={`ticket-select-button ${checkIsError() && 'with-error'}`}
                isNewField={isNewField}
                isError={checkIsError()}
                isEmptyField={isEmptyField}
                data-testid={`ticket-select-${fieldName}`}
                data-cy={`ticket-select-${fieldName}`}
              >
                {showWarningIcon() ? (
                  <div className="warning-icon">
                    <Tooltip isError title={mandatoryFieldError} placement="top" text={<WarningIcon />} />
                  </div>
                ) : null}
                <Tooltip
                  style={{ fontSize: '0.875rem' }}
                  title={tooltipText}
                  forceShowOnHover={tooltipText != null}
                  isTruncatedText
                  text={getTooltipText()}
                  placement="top"
                />
                {isTemplatePage && !field?.templateFieldAttributes?.alwaysReadOnly && (
                  <ArrowDownIcon data-testid="arrow-down-icon" />
                )}
              </TicketBodySelectButton>
            </>
          )}
          <DropdownSelect
            fieldName={fieldName}
            selection={selection}
            options={options}
            keyString={keyString}
            handleChange={onSelectChange}
            handleClose={handleClose}
            anchor={anchor}
            handleClearValue={handleClearValue}
            handleOpen={handleOpen}
            sortBy={sortBy}
            // responsibleManager field requires using a separate endpoint (old one) with different structure.
            // Can be removed once API is consolidated to a single one (the new one).
            backendQueryConfig={backendQueryConfig}
            isActionsHidden={fieldName === 'status' || fieldName === 'priority'}
            MenuProps={MenuProps}
          />
        </>
      )}
    </>
  );
};

export default memo(FieldSearchableDropdown);
