import { useRef, useState, useEffect } from 'react';
import Tooltip from 'common/components/tooltip';
import { StyledChip } from 'features/queue/gridFilters/style';
import { ReactComponent as CloseChipIcon } from 'images/icons/chipDelete.svg';
import { ReactComponent as PlusAttach } from 'images/icons/plus-attach.svg';
import { StyledListWrapper, StyledMultiSelectLabel, StyledPlusWrapper } from './style';

export default function MultiSelectChipList({
  keyString,
  itemList,
  captionString,
  handleOpen,
  handleDelete,
  placeholder,
  parentHeight,
  isDropdownOpen,
  disabled,
  readOnly = false,
  isWorkFlowMultiList = false
}) {
  const listRef = useRef(null);
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    setListHeight(listRef?.current?.offsetHeight);
  }, [itemList]);

  return (
    <StyledListWrapper
      className="multi-select-list-wrapper"
      ref={listRef}
      listHeight={listHeight}
      isDropdownOpen={isDropdownOpen}
    >
      {!itemList?.length && (
        <StyledMultiSelectLabel className="empty-multi-select-chip-placeholder">{placeholder}</StyledMultiSelectLabel>
      )}
      {Array.isArray(itemList) &&
        itemList.map((item) => (
          <StyledChip
            className="multi-select-chip"
            data-testid="multi-select-chip"
            disableCursor = {disabled}
            readOnly = {readOnly}
            isWorkFlowMultiList = {isWorkFlowMultiList}
            key={item[keyString]}
            variant="outlined"
            deleteIcon={<CloseChipIcon />}
            onDelete={() => handleDelete(item?.[keyString])}
            label={
              <Tooltip
                placement="top"
                isTruncatedText
                title={item[captionString]}
                text={item[captionString]}
                style={{ fontSize: '0.875rem', lineHeight: 1.2858, color: '#303030' }}
              />
            }
          />
        ))}
      {!isDropdownOpen && (
        <StyledPlusWrapper className="plus-button" listHeight={parentHeight || listHeight || 0} disabled={disabled} readOnly={readOnly}>
          <PlusAttach data-testid="multiselect-plus-btn" onClick={handleOpen} />
        </StyledPlusWrapper>
      )}
    </StyledListWrapper>
  );
}
