import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import StatusMessage from 'common/components/statusMessage';
import { ReactComponent as LockIcon } from 'images/icons/lock.svg';
import useTexts from '../useTexts';

function SrLockHeader({ lockingDetails }) {
  const userAccount = useSelector(selectActiveUser);
  const { lockedByText } = useTexts();
  return (
    <div className="sr-lock-header">
      {lockingDetails && lockingDetails.isLocked && lockingDetails.lockingUser !== userAccount?.username && (
        <StatusMessage message={`${lockedByText} ${lockingDetails.lockingUser}`} AlertIcon={LockIcon} />
      )}
    </div>
  );
}
export default SrLockHeader;
