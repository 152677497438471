import { memo, useRef, useContext, useMemo, useCallback, useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Scrollbars from 'react-custom-scrollbars-2';
import { CONSTANTS } from 'common/components/assignee/constants';
import GroupedDropdownOptions from 'common/components/groupedDropdownOptions';
import { VirtualizedScrollContext } from './VirtualizedScrollContext';
import useKeyPress from '../hooks/useKeyPress';
import { useSelectContext } from '../context';

const renderThumbVertical = () => (
  <div
    style={{
      position: 'relative',
      display: 'block',
      width: '100%',
      cursor: 'pointer',
      borderRadius: 'inherit',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      height: '112px',
      transform: 'translateY(0px)',
    }}
  />
);

const renderTrackVertical = () => (
  <div
    style={{
      position: 'absolute',
      width: '6px',
      borderRadius: '3px',
      right: '12px',
      bottom: '2px',
      top: '2px',
    }}
  />
);

export const VirtualizedScroll = memo(
  ({
    ids,
    indexOffset,
    additionalWrapperClassName,
    dropdownHeightExtension,
    hasHierarchy,
    isWideDropdown,
    isGroupedHierarchy,
  }) => {
    const { getItemSize, rowRenderer, listRef: contextListRef } = useContext(VirtualizedScrollContext);
    const { setCursor, cursor } = useSelectContext();
    const list = useRef(null);
    const downPress = useKeyPress('ArrowDown');
    const upPress = useKeyPress('ArrowUp');

    const scrollProps = useMemo(
      () => ({
        autoHeight: true,
        autoHeightMin: 230,
        renderThumbVertical,
        renderTrackVertical,
      }),
      [],
    );

    const handleScroll = ({ target }) => {
      const { scrollTop } = target;
      list.current.scrollTo(scrollTop);
    };

    const resetScrollPosition = (node) => {
      if (node) {
        list.current.scrollTo(node.getScrollTop());
      }
    };

    const curriedRowRenderer = useCallback(
      (rowRendererProps) => {
        const { index } = rowRendererProps;
        const isInFocus = index === cursor;
        return rowRenderer({ ids, ...rowRendererProps, isInFocus });
      },
      [rowRenderer, ids, cursor],
    );

    useEffect(() => {
      if (contextListRef && list.current) {
        contextListRef.current = list.current;
      }
    });

    const filteredOptionsLength = hasHierarchy ? Object.values(ids).flatMap((value) => value).length : ids?.length;

    useEffect(() => {
      if (ids.length && downPress) {
        setCursor((prevState) => {
          if (prevState === null) return 0;
          const prev = Number(prevState);
          return prev < ids.length - 1 ? prev + 1 : prev;
        });
      }
    }, [downPress, setCursor, ids.length]);

    useEffect(() => {
      if (upPress) {
        setCursor((prevState) => {
          const prev = Number(prevState);
          return prev > 0 ? prev - 1 : prev;
        });
      }
    }, [upPress, setCursor]);

    useEffect(() => setCursor && setCursor(null), [setCursor]);

    return (
      <div
        style={{
          minHeight: `${Math.min(filteredOptionsLength * CONSTANTS.DROPDOWN_ITEM_HEIGHT, 230) + dropdownHeightExtension
            }px`,
        }}
      >
        {hasHierarchy ? (
          <Scrollbars
            data-cy="options-container-scrollable"
            className={`scroll_wrapper ${additionalWrapperClassName}`}
            ref={!hasHierarchy ? resetScrollPosition : undefined}
            onScroll={!hasHierarchy ? handleScroll : undefined}
            {...scrollProps}
          >
            <GroupedDropdownOptions
              optionsGroups={ids}
              isWideDropdown={isWideDropdown}
              isGroupedHierarchy={isGroupedHierarchy}
            />
          </Scrollbars>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <Scrollbars
                data-cy="options-container-scrollable"
                className={`scroll_wrapper ${additionalWrapperClassName}`}
                ref={!hasHierarchy ? resetScrollPosition : undefined}
                onScroll={!hasHierarchy ? handleScroll : undefined}
                style={{ height, width }}
                {...scrollProps}
              >
                <List
                  height={height}
                  width={width}
                  itemSize={getItemSize}
                  style={{ overflow: false }}
                  ref={list}
                  itemCount={ids?.length + indexOffset}
                >
                  {curriedRowRenderer}
                </List>
              </Scrollbars>
            )}
          </AutoSizer>
        )}
      </div>
    );
  },
);
