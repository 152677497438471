import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectForceShowValidationError } from 'store/fieldValidationsSlice';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import { ReactComponent as WarningIcon } from 'images/icons/warning_icon.svg';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { convertFromClientFieldId, fieldValueExists } from 'common/utils/fieldUtils';
import { getFieldAttributes } from 'services/ticketService';
import { ClickAwayListener } from '@mui/material';
import { isLocalHost } from 'services/localhost';
import MultiSelectChipList from './MultiSelectChipList';
import {
  MultiSelectFieldWrapper,
  StyledDropDownWrapper,
  StyledMultiSelectField,
  StyledMultiSelectFieldInner,
} from './style';

export default function MultiSelectField({
  itemList,
  selectedItemList,
  handleSelectItems,
  onFocus,
  keyString,
  captionString,
  fieldPlaceholder,
  srType,
  fieldId,
  customColumn,
  required,
  isTemplatePage,
  isNewField,
  readOnly = false,
  disabled = false,
  isWorkFlowMultiList = false,
}) {
  const forceShowError = useSelector(selectForceShowValidationError);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPlusClicked, setIsPlusClicked] = useState(false);
  const globalRef = useRef(null);
  const multiSelectRef = useRef(null);

  const mappedItems = useMemo(() => {
    const mapList = new Map();
    if (Array.isArray(itemList)) {
      itemList.forEach((item) => mapList.set(String(item[keyString]), item));
    }
    return mapList;
  }, [itemList, keyString]);

  const chipList = useMemo(() => {
    if (Array.isArray(selectedItemList)) {
      return selectedItemList?.reduce((result, item) => {
        if (mappedItems.has(String(item))) {
          result.push(mappedItems.get(String(item)));
        }
        return result;
      }, []);
    }
    return [];
  }, [mappedItems, selectedItemList]);

  const handleOpenDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    } else {
      if (!disabled && !readOnly) {
        setIsDropdownOpen(true);
        setIsPlusClicked(true);
      }
      if (onFocus) {
        onFocus();
      }
    }
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleChange = useCallback(
    (selectedValues, optionsMap) => {
      handleSelectItems(selectedValues, optionsMap);
    },
    [handleSelectItems],
  );

  const filterAndHandleSelection = (id) => {
    setIsPlusClicked(true);
    const filteredItems = selectedItemList.filter((item) => item.toString() !== id.toString());
    handleSelectItems(filteredItems);
  };

  const handleDeleteChip = (id) => {
    if (disabled || readOnly) return;
    if (onFocus && !isLocalHost()) {
      const callResult = onFocus();
      if (callResult instanceof Promise) {
        callResult.then(() => {
          filterAndHandleSelection(id);
        });
      } else {
        filterAndHandleSelection(id);
      }
    } else {
      filterAndHandleSelection(id);
    }
  };

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) =>
        getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { query, srType, customColumn }),
    }),
    [fieldId, srType, customColumn],
  );

  const checkIsError = () => {
    const isEmptyRequiredValue =
      isPlusClicked &&
      required &&
      !fieldValueExists({
        value: chipList,
        fieldId,
        fieldTypeId: SR_PANEL_CONSTANTS.MULTI_SELECT,
      });
    const newFieldError = isNewField && isEmptyRequiredValue && forceShowError;
    const existingFieldError = !isNewField && isEmptyRequiredValue;
    return newFieldError || existingFieldError;
  };
  const isError = checkIsError();

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <MultiSelectFieldWrapper
        className="multi-select-field-wrapper"
        isDropdownOpen={isDropdownOpen}
        ref={globalRef}
        error={isError}
        isTemplatePage={isTemplatePage}
        disabled={disabled}
        readOnly={readOnly}
        isSrPage // TODO remove this when onClick on whole multi select field is fixed (SR page)
      >
        <StyledMultiSelectField className="multi-select-field" ref={multiSelectRef}>
          <StyledMultiSelectFieldInner>
            <MultiSelectChipList
              handleDelete={handleDeleteChip}
              isDropdownOpen={isDropdownOpen}
              handleOpen={handleOpenDropdown}
              keyString={keyString}
              captionString={captionString}
              itemList={chipList}
              placeholder={fieldPlaceholder}
              parentHeight={multiSelectRef.current?.offsetHeight}
              disabled={disabled}
              readOnly={readOnly}
              isWorkFlowMultiList={isWorkFlowMultiList}
            />
            {isError && <WarningIcon className="warning-icon" />}
          </StyledMultiSelectFieldInner>

          {isDropdownOpen && (
            <StyledDropDownWrapper data-testid="multi-select-dropdown" className="multi-select-dropdown">
              <DropdownSelect
                autoInputFocus
                options={itemList}
                handleChange={handleChange}
                stylingVariant={itemClassNames.reporter}
                captionString={captionString}
                keyString={keyString}
                isMultiple
                backendQueryConfig={backendQueryConfig}
                selection={selectedItemList}
              />
            </StyledDropDownWrapper>
          )}
        </StyledMultiSelectField>
      </MultiSelectFieldWrapper>
    </ClickAwayListener>
  );
}
