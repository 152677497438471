import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FilesPreview from 'common/components/thumbnail/FilesPreview';
import { ReactComponent as UploadAttachmentIcon } from 'images/icons/akar-icons_plus.svg';
import { useTicketAttachments } from 'remote-state/attachments';
import { baseURL } from 'services/axiosBaseInstance';
import { selectActiveUploads, clearFailedUploads } from 'store/attachmentsSlice';
import PropTypes from 'prop-types';
import { StyledActionItemFileInputLabel, StyledFileInputLabel } from './addAttachmentButton/style';
import { useUploadAttachments } from './hooks/useUploadAttachments';
import { ProgressBar } from './progressBar';
import { StyledContainer } from './style';
import { AttachmentPath, SubResourceType } from '../../../../services/attachments';
import { useScreenCaptures } from '../../../../remote-state/useScreenCapturesHooks';
import { AddAttachmentText } from './addAttachmentText';
import { MoreAttachmentsWrapper } from '../../../attachments/attachmentsList/MoreAttachmentsWrapper';
import { AddAttachmentButton } from './addAttachmentButton';

const Attachments = ({ disabled, showTicketAsTab, isActionItem = false, actionItemId = null, isTicketLocked }) => {
  const wrapperRef = useRef(null);
  const activeUploads = useSelector(selectActiveUploads);
  const [selectedFileIndex, setSelectedImageIndex] = useState(0);
  const [displayPreview, setDisplayPreview] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearFailedUploads()), [dispatch]);

  const {
    srAttachments: { data: attachments = [] },
    updateSRAttachmentsAfterDelete,
  } = useTicketAttachments();
  const {
    handleActiveUploads,
    handleUploadNewAttachment,
    handleUploadMultipleAttachments
  } = useUploadAttachments({ showTicketAsTab, actionItemId });

  const { screenCaptures = [] } = useScreenCaptures();
  const handleClose = (id) => {
    handleActiveUploads({ type: 'removeUpload', payload: { id } });
  };

  const handleDeleteAttachment = (attachmentId) => {
    updateSRAttachmentsAfterDelete(attachmentId);
  };
  const handleDisplayPreview = (index) => {
    setSelectedImageIndex(index);
    setDisplayPreview(true);
  };

  const filesData = [];
  for (const attachment of attachments) {
    const {
      attachment: {
        metadata: {
          id: attachmentId,
          originalName,
          extension,
          userName: username,
          createdDate,
          fileId,
          subResourceId,
          subResourceType,
        },
      },
      count,
    } = attachment;
    const fileUrl = `${baseURL}${AttachmentPath.V3}${attachmentId}/payload`;
    const fileData = {
      key: attachmentId,
      attachmentId,
      fileId,
      src: fileUrl,
      fileName: originalName,
      extension,
      userName: username,
      createdDate,
      removeParams: { fileUrl },
      attachmentUsageCount: count,
    };
    if (actionItemId) {
      if (subResourceId === actionItemId) {
        filesData.push(fileData);
      }
    } else if (subResourceType !== SubResourceType.ACTION_ITEM) {
      filesData.push(fileData);
    }
  }

  for (const screenCapture of screenCaptures) {
    filesData.push({
      ...screenCapture,
      src: screenCapture.fileUrl,
      key: screenCapture.fileName,
      attachmentId: screenCapture.fileName,
      screenCaptureType: screenCapture.type,
      isScreenCapture: true,
    });
  }
  const handleImageRemove = () => {
    setDisplayPreview(false);
  };

  return (
    <StyledContainer isActionItem={isActionItem} ref={wrapperRef}>
      {!disabled &&
        (isActionItem ? (
          <StyledActionItemFileInputLabel isActionItem={isActionItem} isTicketLocked={isTicketLocked}>
            <AddAttachmentText
              handleUploadNewAttachment={handleUploadNewAttachment}
              handleUploadMultipleAttachments={handleUploadMultipleAttachments}
            />
          </StyledActionItemFileInputLabel>
        ) : (
          <StyledFileInputLabel isTicketLocked={isTicketLocked}>
            <AddAttachmentButton
              btnComponent={UploadAttachmentIcon}
              handleUploadNewAttachment={handleUploadNewAttachment}
              handleUploadMultipleAttachments={handleUploadMultipleAttachments}
            />
          </StyledFileInputLabel>
        ))}
      <MoreAttachmentsWrapper
        isActionItem={isActionItem}
        wrapperRef={wrapperRef}
        attachments={filesData}
        handleDisplayPreview={handleDisplayPreview}
        handleDeleteAttachment={handleDeleteAttachment}
        disabled={disabled}
        isTicketLocked={isTicketLocked}
        hideButton={!isActionItem}
      />
      {activeUploads.map(
        (upload) =>
          ((!isActionItem && !upload?.actionItemId) || (isActionItem && upload?.actionItemId === actionItemId)) && (
            <ProgressBar
              key={upload.id}
              id={upload.id}
              fileName={upload.fileName}
              uploadProgress={upload.progress}
              error={upload.error}
              onClose={handleClose}
              isActionItem={isActionItem}
            />
          ),
      )}
      {displayPreview && (
        <FilesPreview
          filesData={filesData}
          selectedFileIndex={selectedFileIndex}
          onClose={() => setDisplayPreview(false)}
          onFileRemove={handleImageRemove}
        />
      )}
    </StyledContainer>
  );
};

Attachments.propTypes = {
  showTicketAsTab: PropTypes.bool,
  isActionItem: PropTypes.bool,
  isTicketLocked: PropTypes.bool,
  actionItemId: PropTypes.number,
  disabled: PropTypes.bool,
};

export default Attachments;
