import { useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';

const useValidateSrLockBeforeUpdate = ({ srId, handleSaveSR }) => {
  const waitForLock = useRef();
  const activeUser = useSelector(selectActiveUser);
  const queryClient = useQueryClient();

  const { data: lockingDetails } = useTicketLockStatus(srId);

  const isLocked = lockingDetails?.isLocked;
  const lockingUser = lockingDetails?.lockingUser;
  const checkAvailableTicket = isLocked && activeUser?.username === lockingUser;

  const handleUpdateSR = useCallback(
    async (object) => {
      if (checkAvailableTicket) {
        handleSaveSR(object);
      } else {
        queryClient.invalidateQueries(['ticketLockStatus', srId]);
        waitForLock.current = object;
      }
    },
    [checkAvailableTicket, handleSaveSR, queryClient, srId],
  );

  const handleWaitForLock = useCallback(async () => {
    if (checkAvailableTicket && waitForLock.current) {
      handleSaveSR(waitForLock.current);
      waitForLock.current = null;
    }
  }, [checkAvailableTicket, handleSaveSR]);

  useEffect(() => {
    handleWaitForLock();
  }, [handleWaitForLock]);

  return { handleUpdateSR };
};

export default useValidateSrLockBeforeUpdate;
