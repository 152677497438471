import { useState, useRef, useEffect } from 'react';
import { Popper } from '@mui/material';

import NavItem from '..';
import NavSubList from '../navSubList';

export default function NavItemMenu({ classNames, title, icon, menu, expanded, disableTooltip = false }) {
  const [open, setOpen] = useState(false);
  const [marginTop, setMarginTop] = useState(0);
  const anchorRef = useRef(null);
  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (window.innerHeight <= 1025) {
      setMarginTop(-50);
    } else {
      setMarginTop(0);
    }
  }, []);

  const closeMenu = (event) => {
    if (event.target.classList.contains('ok-btn')) {
      return;
    }
    if (event.currentTarget.classList.contains('nav-toggle-item-189')) {
      // nav-toggle-item-189 it is a 'Resource Center' button in menu. The menu should not
      // be closed after the click on this button.
      // The className comes from NavSubListToggle component, do not update it.
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <NavItem
        classNames={classNames}
        title={title}
        icon={icon}
        ref={anchorRef}
        isActive={open}
        handleClick={toggleMenu}
        expanded={expanded}
        disableTooltip={disableTooltip}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="right-start"
        sx={{ marginTop: `${marginTop}px!important` }}
      >
        <NavSubList expanded={expanded} title={title} sublist={menu} closeMenu={closeMenu} ref={anchorRef.current} />
      </Popper>
    </>
  );
}
