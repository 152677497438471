import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as HomeIcon } from 'images/icons/iconHome.svg';
import Checkbox from '../../checkbox';
import { useOptionById, useSelectContext } from '../context';
import { StyledListItemText, StyledMenuItem } from './SelectItem.styles';
import Tooltip from '../../tooltip';
import { useTexts } from '../hooks';
import { getOptionClass } from '../context/helpers';
import useKeyPress from '../hooks/useKeyPress';
import { ExpandButton } from "../ExpandButton";

export const SelectItem = ({ id, elementType, isInFocus }) => {
  const option = useOptionById(id);
  const {
    selection,
    options,
    stylingVariant,
    isMultiple,
    keyString,
    captionString,
    classString,
    styleString,
    showHomeIcon,
    isWideDropdown = false,
    toggleCheckOption,
    hasHierarchy,
    className,
    isCategory,
  } = useSelectContext();
  const { categoryNoValue, expandSwitch } = useTexts();

  const [isChecked, setIsChecked] = useState(false); // do not remove state as for some reason the mui checkbox doesn't rerender if we don't use it
  const optionClass = hasHierarchy ? getOptionClass(id, options, keyString) : undefined;
  const isDisabled = optionClass && selection.valueClasses?.includes(optionClass);
  const isExpandButton = option.valueCaption === expandSwitch;
  const enterPress = useKeyPress('Enter');

  useEffect(() => {
    const selectedItems = hasHierarchy ? selection.values : selection;
    setIsChecked(
      Boolean(
        isMultiple && (selectedItems?.map(String).includes(String(id)) || (optionClass && selection.valueClasses?.includes(optionClass))),
      ),
    );
  }, [isMultiple, hasHierarchy, id, options, keyString, selection, optionClass]);

  const toggleChecked = useCallback(() => {
    if (!isDisabled && !isExpandButton) {
      toggleCheckOption(id);
    }
  }, [id, toggleCheckOption, isDisabled, isExpandButton]);

  useEffect(() => {
    if (enterPress && isInFocus) {
      toggleChecked();
    }
  }, [enterPress, toggleChecked, isInFocus]);

  const displayHomeIcon = showHomeIcon === option?.id;
  const selectTitle = option[captionString] || (isCategory ? categoryNoValue : '');
  return (
    <StyledMenuItem
      className={`select-item ${className}`}
      key={option && option[keyString]}
      value={selectTitle}
      onClick={toggleChecked}
      checked={isChecked}
      disabled={isDisabled}
      stylingVariant={stylingVariant}
      displayHomeIcon={displayHomeIcon}
      disableRipple
      component={elementType || undefined}
      data-testid={(option && option[keyString]) || id}
      data-cy={(option && `${option[keyString]}${option.valueClass !== undefined && `-type-${option.valueClass}`}`) || id}
      isMultiple={isMultiple}
      isWideDropdown={isWideDropdown}
      isInFocus={isInFocus}
      isExpandButton={isExpandButton}
    >
      {displayHomeIcon && <HomeIcon />}
      {isMultiple && <Checkbox checked={isChecked} disabled={isDisabled} />}
      <StyledListItemText stylingVariant={stylingVariant} color={option && option[classString]} style={option && option[styleString]}>
        <Tooltip displayHomeIcon={displayHomeIcon} isTruncatedText title={selectTitle} text={selectTitle} placement="top" />
      </StyledListItemText>
      {isExpandButton && <ExpandButton />}
    </StyledMenuItem>
  );
};
