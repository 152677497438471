export const FEATURE_FLAGS = {
  HIDE_MESSAGES_CONTENT: 0,
};

export const FEATURE_FLAGS_KEYS = {
  ATTACHMENT_SIZE_LIMITATION: 'attachment-size-limitation',
  QUEUE_INLINE_EDIT: 'queue-inline-edit',
  SHOW_EXTERNAL_PAGES_IN_IFRAME: 'show-external-links-in-iframe',
  PROJECT_ONE_ENABLED: 'project-one',
  SPACES_ACTIVITIES_MVP: 'spaces_activities_mvp',
  REAL_TIME_UPDATES: 'real_time_updates',
  AUTO_POPULATED_TICKETS_SPACES: 'ai-auto-populating-tickets-in-spaces',
  ADMIN_CHATBOT_ENABLED: 'admin-chatbot-copilot',
  SHOW_FEEDBACK_ON_SWITCH_TO_CLASSIC: 'show-feedback-modal-on-switch-to-classic',
  MENU_ITEM_TOGGLE_ENABLED: 'menu-item-toggle-enabled',
  SR_CONVERSION: 'convert-incident-request-in-spaces',
};
