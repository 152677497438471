import { useCallback, useMemo } from 'react';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import ForwardRefFilter from 'common/components/forwardRefFilter';
import { getIsCategory, getIsUserFieldByFieldName } from 'common/utils/fieldUtils';
import { FILTER_TYPE_CONFIGURATIONS, RESPONSIBLE_MANAGER_FIELD_ID } from '../constants';
import ActionBtnRenderer from '../actionBtnRenderer';
import DefaultCellRender from '../defaultCellRender';
import CustomSelectBox from '../customSelectBox';

const requiredFields = ['status', 'assignee', 'srType'];

export const useGetColumnDefinitions = (sr, getColumnText) => {
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { data: currentUserPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const isBulkActionsEnabled = currentUserPermissions?.userPermissionEnableBulkActionsFromList;
  const staticColumns = useMemo(() => {
    if (isBulkActionsEnabled) {
      return [
        {
          headerName: '',
          field: 'delete',
          lockPosition: true,
          pinned: 'left',
          resizable: false,
          width: 20,
          headerClass: 'deleteClassName',
          cellRenderer: ActionBtnRenderer,
        },
        {
          headerName: '',
          field: 'checkbox',
          checkboxSelection: true,
          lockPosition: true,
          pinned: 'left',
          lockPinned: true,
          resizable: false,
          width: 32,
          minWidth: 32,
          suppressSizeToFit: true,
          headerClass: 'checkboxClassName',
          cellRenderer: CustomSelectBox,
        },
        {
          headerName: '',
          fieldTypeName: 'OPEN_LINK',
          field: 'openticket',
          lockPosition: true,
          pinned: 'left',
          lockPinned: true,
          resizable: false,
          width: 70,
          minWidth: 32,
          suppressSizeToFit: true,
          headerClass: 'opensas',
          cellRenderer: DefaultCellRender,
        },
      ];
    }
    return [
      {
        headerName: '',
        field: 'delete',
        lockPosition: true,
        pinned: 'left',
        resizable: false,
        width: 20,
        headerClass: 'deleteClassName',
        cellRenderer: ActionBtnRenderer,
      },
      {
        headerName: '',
        fieldTypeName: 'OPEN_LINK',
        field: 'openticket',
        lockPosition: true,
        pinned: 'left',
        lockPinned: true,
        resizable: false,
        width: 70,
        minWidth: 32,
        suppressSizeToFit: true,
        headerClass: 'opensas',
        cellRenderer: DefaultCellRender,
      },
    ];
  }, [isBulkActionsEnabled]);

  const getColumnConfigByType = useCallback(({ fieldType, fieldId, fieldName }) => {
    const selectFieldParams = {
      sortBy: getIsUserFieldByFieldName(fieldName) ? 'valueCaption' : undefined,
      keyString: fieldId === RESPONSIBLE_MANAGER_FIELD_ID ? 'value' : 'valueKey', // fieldId === CC_USER_FIELD_ID @TODO return this when the bug on the BE regarding CC filter is fixed
      captionString: 'valueCaption',
    };
    const defaultConfig = {
      minWidth: 90,
      width: 150,
      maxWidth: 500,
    };
    const params = (() => {
      switch (fieldType) {
        case 'TEXT':
          return {
            ...defaultConfig,
            minWidth: 150,
            width: 240,
            maxWidth: undefined,
          };
        case 'LONGTEXT':
          return {
            ...defaultConfig,
            minWidth: 150,
            width: 240,
            maxWidth: undefined,
          };
        case 'NUMBER':
          if (fieldName === 'id') {
            return {
              ...defaultConfig,
              pinned: 'left',
              lockPosition: true,
              lockPinned: true,
              width: 90,
              resizable: false,
              suppressSizeToFit: true,
            };
          }
          return {
            ...defaultConfig,
            maxWidth: 150,
          };
        case 'MULTISELECT':
          if (fieldName === 'cc') {
            return {
              ...defaultConfig,
              ...selectFieldParams,
              width: 250,
            };
          }
          return {
            ...defaultConfig,
            width: 300,
          };
        case 'SELECT':
          if (getIsCategory(fieldId)) {
            return {
              ...defaultConfig,
              minWidth: 140,
              captionString: 'valueCaption',
              width: 140,
            };
          }
          if (fieldName === 'assignee' || fieldName === 'assignedGroup') {
            return {
              ...defaultConfig,
              width: 200,
            };
          }
          if (fieldName === 'requestUser') {
            return {
              ...defaultConfig,
              ...selectFieldParams,
              width: 200,
            };
          }
          if (fieldName === 'requestUserManager') {
            return { ...defaultConfig, width: 250 };
          }
          if (['srType', 'status', 'priority', 'urgency', 'impact'].includes(fieldName)) {
            return {
              ...defaultConfig,
              ...selectFieldParams,
              width: 120,
            };
          }
          return {
            ...defaultConfig,
            ...selectFieldParams,
          };
        case 'DATE':
        case 'TIME_REMAINING':
        case 'DATEANDTIME':
          return {
            ...defaultConfig,
            width: fieldName === 'dueDate' ? 140 : 200,
          };
        default:
          return defaultConfig;
      }
    })();
    return params;
  }, []);

  const columns = useMemo(() => {
    if (sr) {
      let columnsArr;
      //Check if third level category turned off in general settings if so the remove it from list.
      if (!isTripleCategory) {
        columnsArr = sr.filter((col) => col.fieldName !== 'thirdLevelCategory');
      } else {
        columnsArr = sr;
      }
      return columnsArr.map(({ fieldName: name, id, ticketTemplateFieldType, sysaidEntity, displayName, customColumn }) => {
        const headerFromRB = getColumnText && getColumnText(displayName);
        const headerName = headerFromRB || displayName;
        const column = {
          field: name,
          fieldId: id,
          sysaidEntity,
          fieldTypeId: ticketTemplateFieldType?.id,
          fieldTypeName: ticketTemplateFieldType?.typeName?.toUpperCase(),
          sortable: true,
          suppressMenu: true,
          headerClass: `${name}HeaderClass`,
          headerName,
          filter: ForwardRefFilter,
          filterParams: {
            filterConf: FILTER_TYPE_CONFIGURATIONS[ticketTemplateFieldType?.id],
            listKey: name,
          },
          customColumn,
          cellRenderer: DefaultCellRender,
          ...getColumnConfigByType({
            fieldType: ticketTemplateFieldType?.typeName?.toUpperCase(),
            fieldName: name,
            fieldId: id,
            headerName,
          }),
        };
        return column;
      });
    }
    return [];
  }, [sr, getColumnText, getColumnConfigByType, isTripleCategory]);

  const hiddenColumns = useMemo(() => {
    const hiddenColumnsList = [];
    const existingNamesSet = new Set(columns.map((obj) => obj.field));
    requiredFields.forEach((field) => {
      if (!existingNamesSet.has(field)) {
        hiddenColumnsList.push({
          field,
          filter: ForwardRefFilter,
          hide: true,
        });
      }
    });
    return hiddenColumnsList;
  }, [columns]);

  const arrayToReturn = useMemo(
    () => [...staticColumns, ...columns, ...hiddenColumns],
    [staticColumns, columns, hiddenColumns],
  );

  return arrayToReturn;
};
