import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useTicketPermissions, useTicketTemplateData } from 'remote-state/ticketServiceHooks';
import { SR_TYPES } from 'features/TicketPanel/constants';
import { selectIsChatbotOpen, openChatbot } from 'features/loggedInRoutesPanel/slice';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { ReactComponent as Copilot } from 'images/icons/copilot.svg';
import { QUERIES_KEYS } from 'constant';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { StyledNavbar, StyledScrollBar, StyledDivider, StyledBottomDivider } from './style';
import ToggleButton from '../toggleButton';
import NavHeader from './navHeader';
import NewTicket from './newTicket';
import NavSearch from './navSearch';
import NavList from './navList/index';
import NavFooter from './navFooter';
import useTexts from './useTexts';

export default function NavigationBar(props) {
  const { toggleNavBarCollapse, navBarCollapsed } = props;
  const isChatbotOpen = useSelector(selectIsChatbotOpen);
  const dispatch = useDispatch();
  const renderView = ({ style, ...props }) => <div {...props} style={style} />;
  const { data: hasEditPermissions } = useTicketPermissions('create', 1);

  useTicketTemplateData(SR_TYPES.change, true, navBarCollapsed);
  useTicketTemplateData(SR_TYPES.request, true, navBarCollapsed);
  useTicketTemplateData(SR_TYPES.problem, true, navBarCollapsed);
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: isAdminChatbotEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.ADMIN_CHATBOT_ENABLED,
    defaultValue: false,
  });
  const { aiChatbotButtonText } = useTexts();
  const handleToggleChatbot = () => {
    dispatch(openChatbot(!isChatbotOpen));
  };
  return (
    <StyledNavbar
      data-testid="nav-bar"
      data-cy="nav-bar"
      id="nav-bar"
      className={navBarCollapsed ? 'collapsed' : 'expanded'}
    >
      <ToggleButton
        data-testid="nav-bar-toggle-button"
        data-cy="nav-bar-toggle-button"
        toggle={toggleNavBarCollapse}
        leftChevron={!navBarCollapsed}
      />
      <NavHeader navBarCollapsed={navBarCollapsed} />
      <StyledDivider />
      {hasEditPermissions && <NewTicket expanded={!navBarCollapsed} />}
      <NavSearch expanded={!navBarCollapsed} />
      {isAiEnabled && isAdminChatbotEnabled && (
        <Button className="copilot-button" data-testid="copilot-button" onClick={handleToggleChatbot}>
          <Copilot />
          {!navBarCollapsed && (aiChatbotButtonText || 'AI Chatbot')}
        </Button>
      )}
      <StyledScrollBar
        renderView={renderView}
        sx={{ marginBottom: 0 }}
        autoHide={false}
        dataset={{ 'data-testid': 'nav-bar-scrollbar', 'data-cy': 'nav-bar-scrollbar' }}
      >
        <NavList expanded={!navBarCollapsed} />
      </StyledScrollBar>
      <StyledBottomDivider />
      <NavFooter expanded={!navBarCollapsed} />
    </StyledNavbar>
  );
}
