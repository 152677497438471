import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts(srType) {
  const texts = useRbTexts();
  const componentTexts = {
    noTitleText: texts?.['spaces.header.title.noTitleText'],
    srTypeText: texts?.[`spaces.header.srType_${srType}`],
    srTypeNewText: texts?.[`spaces.header.srType_${srType}_new`],
    nextTicketText: texts?.['spaces.header.nextTicket'],
    prevTicketText: texts?.['spaces.header.prevTicket'],
    lockedByText: texts?.['spaces.header.locked'],
  };

  return componentTexts;
}
