import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Box, FormControl } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DropdownSelect, useDropdownControl } from 'common/components/dropdownSelect';
import { StyledDataFilters, StyledTabs, JourneyButton } from './style';
import { useAuditLog } from '../middlePanel/auditLog/hooks';
import { useTabs } from '../middlePanel/auditLog/hooks/useTabs';
import { selectJourneyFilters } from '../middlePanel/auditLog/store/slice';
import useTexts from '../useTexts';
import { RESOLUTION_PANEL_CONSTANTS } from '../constants';
import { MIDDLE_PANEL_TAB_NAMES } from '../middlePanel/constants';

const formatOptions = ({ texts, filters }) =>
  filters.map(({ valueKey, valueCaption }) => ({
    valueKey,
    valueCaption: texts[valueCaption],
  }));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const DataFilters = ({ srId, showTicketAsTab }) => {
  const texts = useTexts();
  const { tabs, selectedTabIndex, selectedTabName } = useTabs({ srId, showTicketAsTab });
  const { handleActiveTab: handleTabChange, handleActiveFilter } = useAuditLog();
  const { filter: selectedFilter } = useSelector(selectJourneyFilters);
  const filterCaption = texts[RESOLUTION_PANEL_CONSTANTS.JOURNEY_SELECT[selectedFilter]?.valueCaption];
  const { anchor, handleOpen, handleClose } = useDropdownControl();
  const options = formatOptions({ texts, filters: RESOLUTION_PANEL_CONSTANTS.JOURNEY_SELECT });
  const handleChange = useCallback(
    ({ valueKey }) => {
      handleActiveFilter(valueKey);
      handleClose();
    },
    [handleActiveFilter, handleClose],
  );

  return (
    <StyledDataFilters>
      <Box id="tabs-wrapper">
        <Box id="tabs-listcontainer">
          <StyledTabs value={selectedTabIndex ?? false} onChange={handleTabChange}>
            {tabs?.map((tab, index) => (
              <Tab
                value={index}
                key={`tab-${tab.name}`}
                label={tab.tabText}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
                data-cy={`simple-tab-${index}`}
              />
            ))}
          </StyledTabs>
        </Box>
        <Box id="tabs-filters">
          {selectedTabName === MIDDLE_PANEL_TAB_NAMES.JOURNEY && (
            <FormControl variant="standard">
              <JourneyButton
                variant="text"
                onClick={handleOpen}
                endIcon={<KeyboardArrowDownIcon fontSize="small" />}
                disableRipple
                id="journeyFilterButton"
                dropdownOpened={Boolean(anchor)}
              >
                {filterCaption}
              </JourneyButton>
              <DropdownSelect
                isHeaderHidden
                options={options}
                selection={selectedFilter}
                anchor={anchor}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                id="journeyFilterDropdown"
                MenuProps={menuProps}
              />
            </FormControl>
          )}
        </Box>
      </Box>
    </StyledDataFilters>
  );
};

export default DataFilters;
