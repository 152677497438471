import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const BarLoader = ({ width, showBorder }) => (
  <Box sx={{ width: width || '174px' }}>
    <Skeleton
      sx={{ backgroundColor: '#f0f1f5', border: showBorder ? '1px solid #e0e0df' : 'none' }}
      width={width || '116px'}
      height={28}
      animation="wave"
      variant="rounded"
    />
  </Box>
);
