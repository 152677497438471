import { Fragment } from 'react';
import { Box, Divider } from '@mui/material';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import StyledNavList from './style';
import useTexts from '../useTexts';
import navIcons from '../navIcons';
import NavItemLink from './navItem/navItemLink';
import NavItemUser from './navItem/navItemUser';
import NavItemMenu from './navItem/navItemMenu';

export default function NavList({ expanded }) {
  const { data: navElements } = useApplicationData(QUERIES_KEYS.MENU_ITEMS);
  const { getNavItemTitle } = useTexts();

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingBottom: '30px' }}>
      {navElements &&
        Object.entries(navElements).map(([section, navItems], sectionIndex) => (
          <Fragment key={sectionIndex}>
            {!!sectionIndex && <Divider />}
            <nav>
              <StyledNavList className={section} expanded={expanded}>
                {navItems?.map(({ id, url, rbToken, isOpenInNewTab, subMenuList, type }) => {
                  const { NavItem, title, icon } = {
                    link: {
                      NavItem: NavItemLink,
                      title: getNavItemTitle(rbToken),
                      icon: navIcons[id],
                    },
                    user: {
                      NavItem: NavItemUser,
                    },
                    menu: {
                      // prevents displaying an item that would open to an empty menu
                      NavItem: subMenuList.every(({ type, subMenuList }) => type === 'menu' && subMenuList.length === 0)
                        ? null
                        : NavItemMenu,
                      title: getNavItemTitle(rbToken),
                      icon: navIcons[id],
                    },
                  }[type];

                  return (
                    NavItem && (
                      <NavItem
                        key={id}
                        id={id}
                        title={title}
                        icon={icon}
                        menu={subMenuList}
                        url={url}
                        isOpenInNewTab={isOpenInNewTab}
                        expanded={expanded}
                      />
                    )
                  );
                })}
              </StyledNavList>
            </nav>
          </Fragment>
        ))}
    </Box>
  );
}
